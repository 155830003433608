import { useMemo } from 'react';
import { yup } from '../utils';
import { usePractitionersState } from '../api/store/practitioners';
import { RadioGroup } from './form/radio-group';
import { PractitionerAvatar } from './avatar';

export function PractitionerRadioGroup({ field, ...props }) {
  const state = usePractitionersState();
  const options = useMemo(() => makePractitionerRadioGroupOptions(state.data), [state.data]);
  return <RadioGroup {...props} field={field} options={options} disabled={state.suspend} />;
}

PractitionerRadioGroup.propTypes = {
  field: yup.string().required().pt(),
};

function makePractitionerRadioGroupOptions(practitioners) {
  return [
    <RadioGroup.Item key="any">
      <div className="w-full flex flex-row items-center justify-center size-14 space-x-2 p-[1px]">
        <RadioGroup.CheckMark />
        <span className="truncate max-w-full text-sm">Any</span>
      </div>
    </RadioGroup.Item>,
    ...Object.values(practitioners)
      .toSorted((a, b) => a.nameText.localeCompare(b.nameText))
      .map((practitioner) => (
        <RadioGroup.Item key={practitioner.id}>
          <div className="w-16 max-w-16 overflow-hidden flex flex-col items-center justify-center size-14 space-y-1 p-[1px]">
            <PractitionerAvatar practitioner={practitioner} className="size-8" />
            <span className="truncate max-w-full text-sm">{practitioner.nameText}</span>
          </div>
        </RadioGroup.Item>
      )),
  ];
}
