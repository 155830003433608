import { useState, useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { yup } from '../../utils';
import { ErrorMessage } from './error-message';
import { useDaysOfMonth } from '../../hooks/use-days-of-month';
import { useToday } from '../../hooks/use-today';
import { TIMEZONE } from '../../constants';
import clsx from 'clsx';

const dayColStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
];

function DefaultDayComponent({ day }) {
  return day.tz(TIMEZONE).date();
}

export function Calendar({
  error = '',
  disablePastMonths = false,
  DayComponent = DefaultDayComponent,
}) {
  const today = useToday(TIMEZONE);
  const [startMonthDate, setStartMonthDate] = useState(today.startOf('month'));
  const days = useDaysOfMonth(startMonthDate);
  const disableGotoPreviousMonth = useMemo(
    () => disablePastMonths && startMonthDate.isSameOrBefore(today.startOf('month')),
    [disablePastMonths, startMonthDate, today],
  );
  const gotoNextMonth = () =>
    setStartMonthDate((current) => current.add(40, 'days').startOf('month'));
  const gotoPreviousMonth = () =>
    setStartMonthDate((current) => current.subtract(20, 'days').startOf('month'));

  return (
    <>
      <div className="max-w-md bg-gray-50 rounded-lg p-5">
        <div className="flex items-center">
          <h2 className="flex-auto text-base font-bold text-gray-900">
            {startMonthDate.format('MMMM YYYY')}
          </h2>
          <button
            type="button"
            className="-my-1.5 flex flex-none items-center justify-center p-1 text-indigo-700 disabled:text-gray-400 enabled:hover:bg-gray-300 rounded-lg"
            onClick={gotoPreviousMonth}
            disabled={disableGotoPreviousMonth}
            data-testid="previous-month"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="-my-1.5 -mr-1.5 flex flex-none items-center justify-center p-1 text-indigo-700 enabled:hover:bg-gray-300 rounded-lg"
            onClick={gotoNextMonth}
            data-testid="next-month"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-5 grid grid-cols-7 text-center text-xs font-semibold leading-6 text-gray-900">
          <div className="text-gray-300">Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div className="text-gray-300">Sat</div>
        </div>
        <div className="mt-2 grid grid-cols-7 gap-x-1 gap-y-1.5 text-sm place-items-center text-gray-600">
          {days.map((day, index) => (
            <div key={day} className={clsx(index == 0 && dayColStartClasses[day.day()])}>
              <DayComponent day={day} />
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage error={error} />
    </>
  );
}

const calendarPropTypes = {
  field: yup.string().required().pt(),
  error: yup.string().pt(),
  disablePastMonths: yup.bool().pt(),
  fieldDateFormat: yup.string().pt(),
};

Calendar.propTypes = {
  ...calendarPropTypes,
};
