import { useMemo } from 'react';
import { useApiPost } from '../client';

export function useSearchPatient() {
  const searchPatient = useApiPost('patient/findbynhinumber');
  return useMemo(
    () => ({
      ...searchPatient,
      execute: async ({ nhiNumber }) => {
        const body = { nhiNumber };
        return await searchPatient.execute(body);
      },
    }),
    [searchPatient],
  );
}
