import { useMemo } from 'react';
import { SidePanelOutlet } from '../../../lib/components/side-panel-outlet';
import { Navigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { dayjs } from '../../../lib/utils';
import { useRelativeDateGeneratorFromUrl } from '../../../lib/hooks/use-relative-date-generator';
import { NewAppointment } from './new-appointment';
import { DayBlock } from './day-block';
import { ListDetails } from './list-details';
import { SearchAppointment } from './search-appointment';
import { MoveAppointment } from './move-appointment';

const URL_DATE_FORMAT = 'YYYYMMDD';

function Dashboard() {
  return (
    <div className="flex flex-nowrap flex-col h-full">
      <Schedules />
      <SidePanelOutlet />
    </div>
  );
}

const GRID_COLUMN_WIDTH_PX = 28;

function Schedules() {
  const { width, ref } = useResizeDetector();
  const generator = useRelativeDateGeneratorFromUrl();

  const style = useMemo(() => {
    const columns = Math.max(1, Math.floor((width ?? 0) / GRID_COLUMN_WIDTH_PX));
    return {
      gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
      gridTemplateRows: 'auto 0 0 0 0 0 0 0 0 0 0',
    };
  }, [width]);

  const schedules = useMemo(
    () =>
      Array.apply(null, Array(14)).reduce((accumulator, _, index) => {
        generator;
        const date = generator.at(index);
        accumulator.push(<DayBlock key={date.t.toISOString()} t={date.t} />);
        return accumulator;
      }, []),
    [generator],
  );

  return (
    <div ref={ref} className="grid gap-x-2 overflow-hidden h-full" style={style}>
      {schedules}
    </div>
  );
}

function NavigateToToday() {
  const today = dayjs().format(URL_DATE_FORMAT);
  return <Navigate to={today} replace />;
}

export const dashboardRoute = {
  path: 'dashboard',
  children: [
    {
      index: true,
      Component: NavigateToToday,
    },
    {
      path: ':date',
      element: <Dashboard />,
      children: [
        {
          path: 'new',
          element: <NewAppointment />,
          handle: {
            panelSize: 'large',
            closePath: '..',
          },
        },
        {
          path: 'search',
          element: <SearchAppointment />,
          handle: {
            panelSize: 'large',
            closePath: '..',
            closeWithoutParams: ['term'],
          },
        },
        {
          path: 'details/:listId',
          children: [
            {
              index: true,
              element: <ListDetails />,
              handle: {
                panelSize: 'extra-large',
                closePath: '../..',
              },
            },
            {
              path: 'move/:appointmentId',
              element: <MoveAppointment />,
              handle: {
                panelSize: 'extra-large',
                closePath: '../..',
              },
            },
          ],
        },
      ],
    },
  ],
};
