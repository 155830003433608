export function useReferralSourceColour(value) {
  switch (value.toLowerCase()) {
    case 'contract':
      return 'shaded-blue';
    case 'recall':
      return 'shaded-violet';
    default:
      return 'shaded-gray';
  }
}
