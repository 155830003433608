import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useAddExtraSlot() {
  const dispatch = useDispatch();
  const addExtraSlot = useApiPost('calendar/addslot');
  return useMemo(
    () => ({
      ...addExtraSlot,
      execute: async ({ listId, arrivalTime }) => {
        const body = {
          calendarListId: listId,
          arrivalTime,
        };
        const addedCalendarSlot = await addExtraSlot.execute(body);
        dispatch(calendarActions.addCalendarSlot({ listId, calendarSlot: addedCalendarSlot }));
        return addedCalendarSlot;
      },
    }),
    [addExtraSlot, dispatch],
  );
}
