import { urlJoin, yup } from '../utils';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useCallback, useRef } from 'react';
import {
  Outlet,
  resolvePath,
  useLocation,
  useMatches,
  useNavigate,
  useOutlet,
  useOutletContext,
} from 'react-router-dom';
import { IconButton } from './icon-button';
import clsx from 'clsx';
import { useEnvironmentClassName } from '../api/store/health';

export function SidePanelOutlet() {
  const sizeRef = useRef('large');
  const outlet = useOutlet();
  const navigate = useNavigate();
  const matches = useMatches();
  const location = useLocation();
  const environmentClass = useEnvironmentClassName();

  // Closing is a cooperation between this outlet and the panel definition
  // (declared in the routes). This outlet provides the actual panel component
  // so it needs to know where to navigate back to when the panel should be
  // closed but it doesn't know the relative path back to the content that
  // caused the panel to open (note to self: maybe it could by detecting when
  // content get loaded into the panel, i.e. by storing the path at the moment
  // content is loaded into the panel, but would that work when open the page
  // via a bookmark?).
  // So, this component provides that close function and the panel definition
  // provides the relative path and a list of params that may need to be
  // striped from the url.
  const onClose = useCallback(() => {
    const panelMeta = matches.at(-1)?.handle;

    const params = new URLSearchParams(location.search);
    for (const param of panelMeta?.closeWithoutParams ?? []) {
      params.delete(param);
    }

    const closePath = panelMeta?.closePath ?? '..';
    const resolved = resolvePath(closePath, location.pathname);

    navigate(urlJoin(resolved.pathname, params));
  }, [location.pathname, location.search, matches, navigate]);

  // Only change the size parameter if there is something mounted in our
  // outlet, otherwise use the size specified by the previous route. This makes
  // sure a new panel gets the right size and the panel retains it's size while
  // closing after the previous component unmounted.
  if (outlet) {
    sizeRef.current = matches?.at(-1)?.handle?.panelSize ?? 'large';
  }

  let style;
  switch (sizeRef.current) {
    case 'small': {
      style = 'w-screen max-w-[672]';
      break;
    }
    case 'extra-large': {
      style = 'w-screen max-w-[1564]';
      break;
    }
    case 'large':
    default: {
      style = 'w-screen max-w-7xl';
      break;
    }
  }

  return (
    <Transition.Root show={!!outlet} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-250"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-400"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-400"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className={clsx(style, 'pointer-events-auto relative')}>
                  <div
                    className={clsx(
                      environmentClass,
                      'flex h-full flex-col overflow-y-scroll bg-white bg-cue',
                    )}
                    data-testid="side-panel"
                  >
                    <Outlet context={{ onClose }} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SidePanelOutlet.propTypes = {
  closeRoute: yup.string().pt(),
};

export function SidePanelContent({ header, footer, className, children }) {
  return (
    <div className="h-full max-h-full w-full flex flex-col">
      {header ? header : null}
      <div className={clsx('relative flex-1 overflow-y-auto', className)}>{children}</div>
      {footer ? footer : null}
    </div>
  );
}

SidePanelContent.propTypes = {
  header: yup.mixed().react().required().pt(),
  footer: yup.mixed().react().required().pt(),
  className: yup.string().pt(),
  children: yup.mixed().react().required().pt(),
};

export function SidePanelTitle({ title, variant }) {
  const { onClose } = useOutletContext();
  let headerStyle;
  switch (variant) {
    case 'fill': {
      headerStyle = 'bg-gray-100 p-6 m-0 border-b border-gray-200';
      break;
    }
    default: {
      headerStyle = 'px-4 sm:px-6 py-6 ';
    }
  }
  return (
    <div className={clsx(headerStyle, 'flex flex-nowrap flex-row w-full flex-0')}>
      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </Dialog.Title>
      <div className="flex-1"></div>
      <IconButton onClick={onClose} label="Close panel" icon={<XMarkIcon />} />
    </div>
  );
}

SidePanelTitle.propTypes = {
  title: yup.string().required().pt(),
  variant: yup.string().pt(),
};
