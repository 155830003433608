import { useController } from 'react-hook-form';
import { yup } from '../../utils';
import { ErrorMessage } from './error-message';
import { useId } from 'react';
import clsx from 'clsx';

export function CheckBox({ field, ...props }) {
  return field ? (
    <ControlledCheckBox {...props} field={field} />
  ) : (
    <UncontrolledCheckBox {...props} />
  );
}

function ControlledCheckBox({ field, ...props }) {
  const { field: inputProps } = useController({ name: field });
  return <UncontrolledCheckBox {...props} {...inputProps} />;
}

ControlledCheckBox.propTypes = {
  field: yup.string().required().pt(),
};

function UncontrolledCheckBox({
  label = '',
  description = '',
  error = '',
  className,
  disabled = false,
  ...inputProps
}) {
  const hasLabel = label.length > 0;
  const hasDescription = description.length > 0;
  const hasError = error.length > 0;
  const id = useId();
  const descriptionId = useId();
  return (
    <div className={className}>
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            {...inputProps}
            id={id}
            type="checkbox"
            className={clsx(
              'h-4 w-4 rounded',
              disabled
                ? 'text-gray-400 bg-gray-200 disabled:cursor-not-allowed'
                : 'text-indigo-600',
              hasError
                ? 'border-error-500 focus:ring-error-600'
                : 'border-gray-300 focus:ring-indigo-600',
            )}
            aria-invalid={hasError}
            aria-describedby={descriptionId}
            disabled={disabled}
          />
        </div>
        <div className={clsx('ml-3 text-sm leading-6', { hidden: !hasLabel && !hasDescription })}>
          <label
            htmlFor={id}
            className={clsx(
              'font-medium',
              hasLabel ? '' : 'hidden',
              hasError ? 'text-error-900' : 'text-gray-900',
            )}
          >
            {label}
          </label>
          <p
            id={descriptionId}
            className={clsx(
              { hidden: !hasDescription },
              hasError ? 'text-error-500' : 'text-gray-500',
            )}
          >
            {description}
          </p>
        </div>
      </div>
      <ErrorMessage error={error} />
    </div>
  );
}

UncontrolledCheckBox.propTypes = {
  label: yup.string().pt(),
  description: yup.string().pt(),
  error: yup.string().pt(),
  className: yup.string().pt(),
  disabled: yup.boolean().pt(),
};

CheckBox.propTypes = {
  field: yup.string().required().pt(),
  ...UncontrolledCheckBox.propTypes,
};

CheckBox.Uncontrolled = UncontrolledCheckBox;
