import ky from 'ky';
// eslint-disable-next-line import/no-unresolved
import memoize from 'memoize';
import { createCache } from 'async-cache-dedupe';
import stringify from 'safe-stable-stringify';
import { useSelector } from 'react-redux';
import { decodeJwt } from 'jose';
import { API_URL } from '../constants';
import { logger } from '../logger';
import { tokensSelector, useSetAuthenticationDetails } from './store/authentication';
import { useAsync, useAsyncCallback } from 'react-async-hook';

const baseClient = ky.extend({
  prefixUrl: API_URL,
  timeout: 30000,
  hooks: {
    beforeRequest: [
      (request) => {
        request.__start = window.performance.now();
      },
    ],
    afterResponse: [
      (request, _, response) => {
        const duration = window.performance.now() - request.__start;
        const method = request.method;
        const url = new URL(response.url).pathname;
        const status = response.status;
        const message = `${method} ${url} ${status} ${duration}ms`;
        if (status >= 400) {
          logger.error({ request, response }, message);
        } else {
          logger.debug(message);
        }
      },
    ],
  },
});

export const client = memoize((token) =>
  baseClient.extend(token?.length > 0 ? { headers: { authorization: `BEARER ${token}` } } : {}),
);

const REFRESH_TOKEN_THRESHOLD_SECONDS = 600;

export function useApiGet(path) {
  return useAsync(async () => {
    const result = await client().get(path).json();
    return result;
  });
}

export function useApiPost(path) {
  const tokens = useSelector(tokensSelector);
  const setAuthenticationDetails = useSetAuthenticationDetails();
  return useAsyncCallback(async (body) => {
    if (tokens?.soopa?.access?.encoded) {
      const accessToken = await tryRefreshToken(
        tokens.soopa.access.encoded,
        tokens.soopa.refresh,
        setAuthenticationDetails,
      );
      const result = await client(accessToken).post(path, { json: body }).json();
      return result;
    } else {
      throw new Error('Missing authentication token');
    }
  });
}

const cache = createCache({ storage: { type: 'memory' } });

cache.define(
  'apiRefreshToken',
  {
    ttl: 30,
    // eslint-disable-next-line no-unused-vars
    serialize: ({ onRefreshed, ...body }) => stringify(body),
  },
  async ({ onRefreshed, ...body }) => {
    logger.debug(`Token expires soon, attempting to refresh`);
    const result = await client().post('authentication/refresh', { json: body }).json();
    await onRefreshed(result);
    logger.info(`Refreshed token`);
    return result;
  },
);

export async function tryRefreshToken(accessToken, refreshToken, setAuthenticationDetails) {
  try {
    const decoded = decodeJwt(accessToken);
    const tokenExpiry = Math.round(decoded.exp - Date.now() / 1000);
    if (tokenExpiry < REFRESH_TOKEN_THRESHOLD_SECONDS && refreshToken?.length > 0) {
      const result = await cache.apiRefreshToken({
        accessToken,
        refreshToken,
        onRefreshed: (result) =>
          setAuthenticationDetails({
            soopaAccessToken: result.accessToken,
            soopaRefreshToken: result.refreshToken,
          }),
      });
      return result.accessToken;
    }
  } catch (error) {
    logger.warn({ error }, 'Failed to refresh token');
  }
  return accessToken;
}
