import { useMemo } from 'react';
import { useLatest } from 'react-use';
import { yup } from '../utils';
import { LoadingListBox, ListBox } from './form/list-box';
import { useEnumState } from '../api/store/enums';

export function EnumListBox({
  name,
  field,
  valuesToOmit = [],
  makeEnumListBoxOptions = defaultMakeEnumListBoxOptions,
  ...props
}) {
  const state = useEnumState(name);
  const latestMakeOptions = useLatest(makeEnumListBoxOptions);
  const options = useMemo(() => {
    return latestMakeOptions.current(
      Object.fromEntries(
        Object.entries(state.data ?? []).filter(([k]) => !valuesToOmit.includes(k)),
      ),
    );
  }, [latestMakeOptions, state.data, valuesToOmit]);
  if (state.suspend) {
    return <LoadingListBox {...props} field={field} />;
  } else {
    return <ListBox {...props} field={field} options={options} />;
  }
}

EnumListBox.propTypes = {
  name: yup.string().required().pt(),
  field: yup.string().required().pt(),
  valuesToOmit: yup.array().of(yup.string()).pt(),
  makeEnumListBoxOptions: yup.mixed().callback().pt(),
};

export function defaultMakeEnumListBoxOptions(enumValues) {
  return Object.entries(enumValues).map(([k, v]) => <ListBox.LabelItem key={k} label={v} />);
}
