import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  transformArrayToMapping,
  INITIAL_THUNK_STATE,
  useStateWithSuspendFlag,
  createAsyncApiThunk,
  useSharedDispatch,
  setThunkStatePending,
  setThunkStateFulfilled,
  setThunkStateRejected,
} from './utils';
import { EMPTY_OBJECT } from '../../constants';
import { logger } from '../../logger';
import { transformPractitioner } from './transformations';

export const fetchPractitioners = createAsyncApiThunk(
  'practitioners/fetch',
  async (_, { apiPost }) => {
    const rawPractitioners = await apiPost('practitioner/search', {});
    logger.info(`Received ${rawPractitioners.length} practitioners`);
    return transformArrayToMapping(rawPractitioners);
  },
);

const practitionersSlice = createSlice({
  name: 'practitioners',
  initialState: { ...INITIAL_THUNK_STATE },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPractitioners.pending, (state) => {
        setThunkStatePending(state);
      })
      .addCase(fetchPractitioners.fulfilled, (state, action) => {
        setThunkStateFulfilled(state, action.payload);
      })
      .addCase(fetchPractitioners.rejected, (state, action) => {
        setThunkStateRejected(state, action.error);
      });
  },
});

export default practitionersSlice.reducer;

const practitionersSelector = createSelector([practitionersSlice.selectSlice], (slice) => {
  const practitioners = Object.values(slice.data).reduce((acc, rawPractitioner) => {
    const practitioner = transformPractitioner(rawPractitioner);
    acc[practitioner.id] = practitioner;
    return acc;
  }, {});
  return { ...slice, data: practitioners };
});

export function usePractitionersState() {
  useSharedDispatch(fetchPractitioners, null, { pollInterval: 1800 * 1000 });
  const state = useSelector(practitionersSelector);
  return useStateWithSuspendFlag(state);
}

export function usePractitioners() {
  const state = usePractitionersState();
  return state.status === 'loading' && !state.loaded ? EMPTY_OBJECT : state.data;
}
