import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { DEBUG } from './constants';
import en from '../localization/en.json';

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: DEBUG,
    resources: { en },
    ns: ['common'],
    defaultNS: 'common',
  });
