import { createContext, useContext, useMemo } from 'react';
import stringify from 'safe-stable-stringify';
import invariant from 'tiny-invariant';
import debounce from 'debounce';
import { customAlphabet, urlAlphabet } from 'nanoid';
import { logger } from '../../logger';
import { yup } from '../../utils';
import { useDispatch } from 'react-redux';

const nanoid = customAlphabet(urlAlphabet, 10);

const DEFAULT_OPTIONS = Object.freeze({
  pollInterval: 60000,
});

function mergeOptions(optionsList) {
  return optionsList.reduce((acc, val) => ({
    pollInterval: Math.min(acc.pollInterval, val.pollInterval ?? Infinity),
  }));
}

class ThunkDispatcher {
  constructor(manager, actionCreator, actionArgs) {
    this._id = `${actionCreator.typePrefix}(${stringify(actionArgs)})`;
    this._dispatch = debounce(() => {
      this._logger.debug({ id: this._id }, 'Thunk dispatcher triggered');
      manager._dispatch(actionCreator(actionArgs));
    }, 100);
    this._timer = undefined;
    this._logger = manager._logger.child({ id: this._id });
    this._optionsHash = '';
  }

  start(options) {
    invariant(!this._timer);
    invariant(options?.pollInterval > 0);
    const interval = Math.round(
      options.pollInterval * 0.9 + options.pollInterval * 0.2 * Math.random(),
    );
    this._logger.debug({ id: this._id, interval }, 'Thunk dispatcher started');
    this._timer = setInterval(() => this._dispatch(), interval);
    this._optionsHash = stringify(options);
    this._dispatch();
  }

  stop() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = undefined;
    }
  }

  update(options) {
    if (options && stringify(options) !== this._optionsHash) {
      this.stop();
      this.start(options);
    }
  }

  trigger() {
    this._dispatch();
  }
}

class ThunkDispatcherManager {
  constructor(dispatch) {
    this._dispatch = dispatch;
    this._thunkDispatchers = new Map();
    this._logger = logger.child({}, { level: 'warn' });
  }

  acquire(actionCreator, actionArgs, options = {}) {
    const id = `${actionCreator.typePrefix}(${stringify(actionArgs)})`;
    const ref = nanoid();
    let entry = this._thunkDispatchers.get(id);
    if (!entry) {
      this._logger.debug({ id, ref }, 'Create thunk dispatcher');
      const instance = new ThunkDispatcher(this, actionCreator, actionArgs);
      entry = { refs: { [ref]: { ...DEFAULT_OPTIONS, ...options } }, instance };
      instance.start(mergeOptions(Object.values(entry.refs)));
      this._thunkDispatchers.set(id, entry);
    } else {
      entry.refs[ref] = { ...DEFAULT_OPTIONS, ...options };
      entry.instance.update(mergeOptions(Object.values(entry.refs)));
      this._logger.trace(
        { id, refs: Object.keys(entry.refs).length, ref },
        'Increment thunk dispatcher ref count',
      );
    }
    return ref;
  }

  release(actionCreator, actionArgs, ref) {
    const id = `${actionCreator.typePrefix}(${stringify(actionArgs)})`;
    setTimeout(() => {
      let entry = this._thunkDispatchers.get(id);
      invariant(entry && Object.hasOwn(entry.refs, ref), 'Invalid call to release');
      delete entry.refs[ref];
      const refs = Object.keys(entry.refs).length;
      if (refs <= 0) {
        entry.instance.stop();
        this._thunkDispatchers.delete(id);
        this._logger.debug({ id, ref }, 'Destroyed thunk dispatcher');
      } else {
        this._logger.trace({ id, refs, ref }, 'Decrement thunk dispatcher ref count');
      }
    }, 100);
  }

  refresh() {
    this._logger.info('Refreshing all data');
    for (const { instance } of this._thunkDispatchers.values()) {
      instance.trigger();
    }
  }
}

const ThunkDispatcherManagerContext = createContext();

export function SharedThunkDispatchManagerProvider({ children }) {
  const dispatch = useDispatch();
  const manager = useMemo(() => new ThunkDispatcherManager(dispatch), [dispatch]);
  return (
    <ThunkDispatcherManagerContext.Provider value={manager}>
      {children}
    </ThunkDispatcherManagerContext.Provider>
  );
}

SharedThunkDispatchManagerProvider.propTypes = {
  children: yup.mixed().react().pt(),
};

export function useSharedThunkDispatchManager() {
  const thunkDispatcherManager = useContext(ThunkDispatcherManagerContext);
  return useMemo(
    () => ({
      subscribe: (actionCreator, actionArgs, options) => {
        const ref = thunkDispatcherManager.acquire(actionCreator, actionArgs, options);
        return () => thunkDispatcherManager.release(actionCreator, actionArgs, ref);
      },
      refresh: () => thunkDispatcherManager.refresh(),
    }),
    [thunkDispatcherManager],
  );
}
