import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { yup } from '../../utils';
import { Tooltip } from '../tooltip';

export function FilledNotes({ notes }) {
  if (!notes) return null;

  return (
    <Tooltip content={notes}>
      <ChatBubbleBottomCenterTextIcon className="size-5" />
    </Tooltip>
  );
}

FilledNotes.propTypes = {
  notes: yup.string().pt(),
};
