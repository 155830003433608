import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { LoginPage } from './page';
import { GOOGLE_CLIENT_ID } from '../../../lib/constants';

export function LoginGoogle() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <LoginPage>
        <LoginGoogleInternal />
      </LoginPage>
    </GoogleOAuthProvider>
  );
}

function LoginGoogleInternal() {
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: 'https://app.soopa.co/authentication/externallogin/logincallback',
    onSuccess: (response) => {
      console.log('GOOGLE LOGIN SUCCESS', response);
    },
    onError: (response) => {
      console.log('GOOGLE LOGIN ERROR', response);
    },
  });
  return <button onClick={googleLogin}>Login</button>;
}
