import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import * as yup from 'yup';
import { isValidElementType } from 'react-is';
import invariant from 'tiny-invariant';
import { isValidElement } from 'react';
import { EMPTY_OBJECT, GUID_REGEX, TIMEZONE } from './constants';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

dayjs.tz.setDefault(TIMEZONE);

yup.addMethod(yup.Schema, 'pt', function () {
  return (props, propName, componentName) => {
    // eslint-disable-next-line no-undef
    if (process?.env?.NODE_ENV !== 'production' && typeof props[propName] !== 'undefined') {
      try {
        props[propName] = this.validateSync(props[propName]);
      } catch (cause) {
        return new Error(
          `${componentName}.${propName}: ${cause.message}, got ${JSON.stringify(props[propName])}`,
          {
            cause,
          },
        );
      }
    }
  };
});

yup.addMethod(yup.mixed, 'react', function () {
  return this.test(
    'is-react-component',
    '${path} is not a react component or element',
    (value) =>
      isValidElementType(value) ||
      isValidElement(value) ||
      (Array.isArray(value) && value.every((x) => isValidElement(x))),
  );
});

yup.addMethod(yup.mixed, 'dayjs', function (strict = true) {
  return this.test('is-dayjs-instance', '${path} is not a dayjs', (value) => {
    if (strict) {
      return dayjs.isDayjs(value);
    } else {
      return dayjs(value).isValid();
    }
  });
});

yup.addMethod(yup.mixed, 'callback', function () {
  return this.test(
    'is-callback',
    '${path} is not a callback',
    (value) => value && typeof value === 'function',
  );
});

yup.addMethod(yup.string, 'guid', function () {
  return this.test('is-guid', '${path} is not a valid guid', (value) => GUID_REGEX.test(value));
});

export function relativeDateString(t, format = 'dddd, MMM D') {
  const today = dayjs().startOf('day');
  const diff = t.startOf('day').diff(today, 'day');
  switch (diff) {
    case 0:
      return 'Today';
    case 1:
      return 'Tomorrow';
    case -1:
      return 'Yesterday';
    default:
      return t.format(format);
  }
}

const URL_DATE_FORMAT = 'YYYYMMDD';

export function formatUrlDate(t) {
  invariant(dayjs.isDayjs(t) && t.isValid(), 'Expected a valid date');
  return t.format(URL_DATE_FORMAT);
}

const URL_DATE_REGEX = /20\d{6}/;

export function parseUrlDate(str) {
  invariant(URL_DATE_REGEX.test(str), 'Expected a valid date string');
  return dayjs(str, URL_DATE_FORMAT).tz(TIMEZONE, true);
}

export { dayjs, yup };

export function urlJoin(...parts) {
  return parts.reduce((acc, val, index) => {
    if (val instanceof URLSearchParams || isPlainObject(val)) {
      const params = new URLSearchParams(val).toString();
      if (params.length > 0) {
        acc += '?';
        acc += params;
      }
      invariant(index === parts.length - 1);
    } else if (typeof val === 'string') {
      acc += '/';
      acc += val;
    } else {
      invariant(false);
    }
    return acc;
  });
}

export function findSlotById(id, schedule) {
  for (let roomIndex = 0; roomIndex < schedule.rooms.length; roomIndex++) {
    const room = schedule.rooms[roomIndex];
    for (let blockIndex = 0; blockIndex < room.blocks.length; blockIndex++) {
      const block = room.blocks[blockIndex];
      const slot = block.slots.find((slot) => slot.id === id);
      if (slot) {
        return {
          slot: slot,
          roomIndex: roomIndex,
          blockIndex: blockIndex,
        };
      }
    }
  }
  return null;
}

export function isPlainObject(value) {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return (
    (prototype === null ||
      prototype === Object.prototype ||
      Object.getPrototypeOf(prototype) === null) &&
    !(Symbol.toStringTag in value) &&
    !(Symbol.iterator in value)
  );
}

export function safeParseJson(json, defaultValue = EMPTY_OBJECT) {
  try {
    return JSON.parse(json);
  } catch {
    return defaultValue;
  }
}
