import { useMemo } from 'react';
import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

export function useDaysOfMonth(date) {
  invariant(dayjs.isDayjs(date) && date.isValid());
  const base = date.startOf('month');
  return useMemo(() => {
    const days = base.endOf('month').date();
    // Note: add hours because dayjs does not correctly handle adding a day
    // across a daylight savings boundary.
    return [...Array(days).keys()].map((i) => base.add(i * 24 + 8, 'hours').startOf('day'));
  }, [base.toISOString()]); // eslint-disable-line react-hooks/exhaustive-deps
}
