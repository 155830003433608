import { yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import { Td, TdArrivalTime, TdSlotNumber } from './shared';
import { Button } from '../button';
import { UpdateWebPasSyncCheckbox } from './update-web-pass-sync-checkbox';

export function FilledSlotRowConfirmDeletion({ index, slot, text }) {
  return (
    <tr className="border-t border-gray-200 bg-red-50">
      <TdSlotNumber index={index} />
      <TdArrivalTime slot={slot} />
      <Td colSpan={9}>
        <Button
          label={text}
          variant="plain"
          color="red"
          type="button"
          className="pointer-events-none mx-auto"
        />
      </Td>
      <Td className="text-center">
        <UpdateWebPasSyncCheckbox slot={slot} />
      </Td>
      <Td></Td>
    </tr>
  );
}

FilledSlotRowConfirmDeletion.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  text: yup.string().pt(),
};
