import clsx from 'clsx';
import { yup } from '../utils';

export function Tooltip({ children, mode, content }) {
  return (
    <div className="inline-block relative group/tooltip">
      {children}
      <div
        className={clsx(
          'absolute text-center w-max max-w-sm min-w-20 top-full mt-4 rounded z-10 right-1/2 transform translate-x-1/2 invisible opacity-0 transition-opacity pointer-events-none group-hover/tooltip:opacity-100 group-hover/tooltip:visible',
          mode === 'error' ? 'bg-red-600' : 'bg-indigo-600',
        )}
      >
        <div className="inline-block text-left text-white text-sm py-2.5 px-4 break-word">
          {content}
        </div>
        <div
          className={clsx(
            'w-0 h-0 absolute right-1/2 transform translate-x-1/2 bottom-full border-l-[10px] border-l-transparent border-b-[10px] border-r-[10px] border-r-transparent',
            mode === 'error' ? 'border-b-red-600' : 'border-b-indigo-600',
          )}
        ></div>
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  content: yup.string().pt(),
  mode: yup.string().oneOf(['info', 'error']).pt(),
  children: yup.mixed().react().pt(),
};
