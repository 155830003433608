import { useMemo } from 'react';
import { createSlice, original } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  INITIAL_THUNK_STATE,
  createAsyncApiThunk,
  assignHashAndKeys,
  transformArrayToMapping,
  useSharedDispatch,
  makeSafeReducer,
  useStateWithSuspendFlag,
  setThunkStateFulfilled,
  setThunkStateRejected,
  setThunkStatePending,
  dateToKey,
} from './utils';
import { API_DATE_FORMAT, EMPTY_ARRAY, TIMEZONE } from '../../constants';
import invariant from 'tiny-invariant';
import { dayjs, yup } from '../../utils';
import { logger } from '../../logger';
import { transformList } from './transformations';

const DAY_KEY_FORMAT = 'YYYY-MM-DD';
const DAY_KEY_REGEX = /^(20[0-9]{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const MONTH_KEY_FORMAT = 'YYYY-MM';
const MONTH_KEY_REGEX = /^(20[0-9]{2})-(0[1-9]|1[0-2])$/;

const LIST_RESPONSE_SCHEMA = yup
  .object({
    id: yup.string().guid().required(),
    date: yup.string().matches(DAY_KEY_REGEX).required(),
    listType: yup.string().required(),
    sessionTime: yup.string().oneOf(['AllDay', 'Morning', 'Afternoon']),
    targetProcedurePoints: yup.number().integer().min(0).required(),
    maximumProcedurePoints: yup.number().integer().min(0).required(),
    practitioner: yup.object().required(),
    room: yup.object().required(),
    availableSlots: yup.number().integer().min(0).required(),
    calendarSlots: yup
      .array()
      .of(
        yup.object({
          id: yup.string().guid().required(),
          status: yup.string().oneOf(['Available', 'Appointment', 'Unavailable']).required(),
          appointment: yup
            .object({
              id: yup.string().guid().required(),
            })
            .nullable(),
        }),
      )
      .required(),
  })
  .required();

export const fetchLists = createAsyncApiThunk('lists/fetch', async (arg, { apiPost }) => {
  invariant(MONTH_KEY_REGEX.test(arg), 'Expected a YYYY-MM key');
  const start = dayjs(arg, MONTH_KEY_FORMAT).tz(TIMEZONE, true);
  const end = start.endOf('month');
  const rawLists = await apiPost('Calendar/ListSearch', {
    startDate: start.format(API_DATE_FORMAT),
    endDate: end.format(API_DATE_FORMAT),
  });
  logger.info(`Received ${rawLists.length} lists`);
  return rawLists.map((rawList) => {
    LIST_RESPONSE_SCHEMA.validateSync(rawList);
    return assignHashAndKeys(
      { ...rawList, calendarSlots: transformArrayToMapping(rawList.calendarSlots) },
      extractKeysFromList,
    );
  });
});

export const fetchList = createAsyncApiThunk('list/fetch', async (arg, { apiGet }) => {
  const rawList = await apiGet(`Calendar/List/${arg}`);
  logger.info(`Received list`);
  LIST_RESPONSE_SCHEMA.validateSync(rawList);
  return assignHashAndKeys(
    { ...rawList, calendarSlots: transformArrayToMapping(rawList.calendarSlots) },
    extractKeysFromList,
  );
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {},
  reducers: {
    updateAppointment: makeSafeReducer((state, action) => {
      const appointment = action.payload;
      const calendarSlotId = appointment.calendarSlotId;
      const listIds = Object.values(original(state))
        .filter((item) => item.$keys?.[calendarSlotId]?.length > 0)
        .map((list) => list.id);
      for (const listId of listIds) {
        const calendarSlot = state[listId]?.calendarSlots?.[calendarSlotId];
        if (calendarSlot) {
          calendarSlot.status = 'Appointment';
          calendarSlot.appointment = appointment;
        }
      }
    }),
    addCalendarSlot: makeSafeReducer((state, action) => {
      const listId = action.payload.listId;
      const calendarSlot = action.payload.calendarSlot;
      const calendarSlots = state[listId]?.calendarSlots;
      if (calendarSlots && calendarSlot) {
        calendarSlots[calendarSlot.id] = calendarSlot;
      }
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLists.pending, (state, action) => {
        if (!Object.hasOwn(state, action.meta.arg)) {
          state[action.meta.arg] = { ...INITIAL_THUNK_STATE };
        }
        const slice = state[action.meta.arg];
        setThunkStatePending(slice);
      })
      .addCase(fetchLists.fulfilled, (state, action) => {
        const slice = state[action.meta.arg];
        setThunkStateFulfilled(slice, {});
        for (const list of action.payload) {
          state[list.id] = list;
          if (!Object.hasOwn(slice.data, list.date)) {
            slice.data[list.date] = [list.id];
          } else {
            slice.data[list.date] = [...slice.data[list.date], list.id].toSorted();
          }
        }
      })
      .addCase(fetchLists.rejected, (state, action) => {
        const slice = state[action.meta.arg];
        setThunkStateRejected(slice, action.error);
      })
      .addCase(fetchList.pending, (state, action) => {
        const key = `fetch-${action.meta.arg}`;
        if (!Object.hasOwn(state, key)) {
          state[key] = { ...INITIAL_THUNK_STATE };
        }
        const slice = state[key];
        setThunkStatePending(slice);
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        const key = `fetch-${action.meta.arg}`;
        const slice = state[key];
        setThunkStateFulfilled(slice, {});
        const list = action.payload;
        state[list.id] = list;
      })
      .addCase(fetchList.rejected, (state, action) => {
        const key = `fetch-${action.meta.arg}`;
        const slice = state[key];
        setThunkStateRejected(slice, action.error);
      });
  },
});

export default calendarSlice.reducer;

export const actions = calendarSlice.actions;
export const slice = calendarSlice.selectSlice;

const dayListsSelector = createSelector(
  [calendarSlice.selectSlice, (_, dayKey) => dayKey],
  (slice, dayKey) => {
    const monthKey = dayKey.slice(0, 7);
    const monthState = slice[monthKey] ?? { ...INITIAL_THUNK_STATE };
    const rawDayLists = monthState.data[dayKey]?.map((listId) => slice[listId]) ?? EMPTY_ARRAY;
    const dayLists = rawDayLists.map((rawList) => transformList(rawList));

    // Restructure the lists for this day into an object that helps draw the
    // dashboard for this day.
    return {
      ...monthState,
      data: dayLists.reduce(
        (acc, list) => {
          const roomId = list.room.id;
          const practitionerId = list.practitioner.id;

          // Add empty room, if not seen before
          if (!Object.hasOwn(acc.rooms, roomId)) {
            acc.rooms[roomId] = {
              ...list.room,
              lists: {},
            };
          }
          acc.rooms[roomId].lists[list.sessionTime.toLowerCase()] = list;

          // Generate availability data
          const emptySlots = list.calendarSlots.filter((x) => !x.appointment);
          if (emptySlots.length > 0) {
            // Add empty practitioner, if not seen before
            if (!Object.hasOwn(acc.availability.practitioners, practitionerId)) {
              acc.availability.practitioners[practitionerId] = {
                ...list.practitioner,
                slots: [],
              };
            }
            acc.availability.practitioners[practitionerId].slots.push(...emptySlots);

            // Compute status summary
            // for (const slot of emptySlots) {
            //   const status = 'green'; // TODO: orange
            //   const index = slot.dayMilliseconds < NOON ? 0 : 1;
            //   if (status > acc.availability.summary[index]) {
            //     acc.availability.summary[index] = status;
            //   }
            // }
          }
          return acc;
        },
        {
          t: dayjs(dayKey).tz(TIMEZONE, true),
          date: dayKey,
          rooms: {},
          availability: {
            practitioners: {},
            summary: ['', ''],
          },
        },
      ),
    };
  },
);

const listSelector = createSelector(
  [calendarSlice.selectSlice, (_, listId) => listId],
  (slice, key) => {
    const rawList = slice[key];
    if (rawList) {
      const monthKey = rawList.date.slice(0, 7);
      const listState = slice[monthKey] ??
        slice[`fetch-${rawList.id}`] ?? { ...INITIAL_THUNK_STATE };
      return { ...listState, data: transformList(rawList) };
    } else {
      return { ...INITIAL_THUNK_STATE };
    }
  },
);

export function useLists(date) {
  const [dayKey, monthKey] = useMemo(
    () => [dateToKey(date, DAY_KEY_FORMAT), dateToKey(date, MONTH_KEY_FORMAT)],
    [date],
  );
  useSharedDispatch(fetchLists, monthKey, { pollInterval: 300 * 1000 });
  const dayState = useSelector((state) => dayListsSelector(state, dayKey));
  return useStateWithSuspendFlag(dayState);
}

export function useList(listId) {
  const listState = useSelector((state) => listSelector(state, listId));

  // Automatically switch between fetching a single list (of which we don't
  // know in which month the list lies). We do this by first fetching the
  // single list, then we know the month and switch to fetching that month.
  // This avoids potential data races between fetchLists and fetchList that
  // might receive different versions of the same list.
  const monthKey =
    listState.data.id?.length > 0 && DAY_KEY_REGEX.test(listState.data.date)
      ? listState.data.date.slice(0, 7)
      : undefined;
  const actionCreator = monthKey ? fetchLists : fetchList;
  const actionArgs = monthKey ? monthKey : listId;
  useSharedDispatch(actionCreator, actionArgs, { pollInterval: 300 * 1000 });

  return useStateWithSuspendFlag(listState);
}

function extractKeysFromList(list) {
  const keys = {};
  for (const calendarSlot of Object.values(list.calendarSlots)) {
    keys[calendarSlot.id] = `calendarSlot[${calendarSlot.id}]`;
    const appointment = calendarSlot.appointment;
    if (appointment) {
      keys[appointment.id] = `calendarSlot[${calendarSlot.id}].appointment`;
      const patient = appointment.patient;
      if (patient) {
        keys[patient.id] = `calendarSlot[${calendarSlot.id}].appointment.patient`;
      }
      const procedure = appointment.procedure;
      if (procedure) {
        keys[procedure.id] = `calendarSlot[${calendarSlot.id}].appointment.procedure`;
      }
    }
  }
  return keys;
}
