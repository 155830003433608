import { useCallback, useId } from 'react';
import { Td } from './shared';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from '../../utils';
import { NHI_REGEX_OPTIONAL } from '../../constants';
import { TextField } from '../form/text-field';
import { Button } from '../button';
import { useSearchPatient } from '../../api/actions/search-patient';
import { useMountedState } from 'react-use';

const NHI_SEARCH_FORM_SCHEMA = yup.object().shape({
  nhiNumber: yup.string().matches(NHI_REGEX_OPTIONAL, 'A valid NHI is required'),
});

const NHI_SEARCH_FORM_DEFAULTS = {
  nhiNumber: '',
};

export function PatientSearchForm({ setFormField, disabled }) {
  const id = useId();
  const nhiSearchForm = useForm({
    resolver: yupResolver(NHI_SEARCH_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: NHI_SEARCH_FORM_DEFAULTS,
  });
  const { isSubmitting, isLoading, isValid } = nhiSearchForm.formState;
  const nhiNumber = nhiSearchForm.watch('nhiNumber');
  const isMounted = useMountedState();

  const setPatientDetails = useCallback(
    ({ nhiNumber, patientId, nameFamily, nameGiven, email, phoneNumber }) => {
      setFormField('nhiNumber', nhiNumber, { shouldValidate: true });
      setFormField('patientId', patientId, { shouldValidate: true });
      setFormField('nameFamily', nameFamily, { shouldValidate: true });
      setFormField('nameGiven', nameGiven, { shouldValidate: true });
      setFormField('email', email, { shouldValidate: true });
      setFormField('phoneNumber', phoneNumber, { shouldValidate: true });
    },
    [setFormField],
  );

  // NHI search
  const searchPatient = useSearchPatient();
  const searchPatientHandler = async (values) => {
    const nhiNumber = values.nhiNumber;
    if (nhiNumber.length > 0) {
      try {
        const result = await searchPatient.execute({ nhiNumber });
        if (isMounted()) {
          setPatientDetails({
            nhiNumber,
            patientId: result.id ?? null,
            nameGiven: result.nameGiven ?? '',
            nameFamily: result.nameFamily ?? '',
            phoneNumber: result.phoneNumber ?? '',
            email: result.email ?? '',
          });
        }
      } catch (error) {
        if (isMounted()) {
          setPatientDetails({
            nhiNumber,
            patientId: null,
            nameGiven: '',
            nameFamily: '',
            phoneNumber: '',
            email: '',
          });
        }
      }
    }
  };

  return (
    <FormProvider {...nhiSearchForm}>
      <Td className="uppercase bg-gray-50">
        <TextField
          form={id}
          field="nhiNumber"
          placeholder="e.g. ABC1234"
          disabled={isSubmitting || isLoading || disabled}
          errorTooltip
        />
      </Td>
      <Td className="uppercase bg-gray-50" colSpan={4}>
        <form className="m-0" id={id} onSubmit={nhiSearchForm.handleSubmit(searchPatientHandler)}>
          <Button
            type="submit"
            form={id}
            label="Search"
            variant="outline"
            color="gray"
            disabled={isSubmitting || isLoading || !isValid || nhiNumber.length <= 0 || disabled}
            testId="nhi-search"
          />
        </form>
      </Td>
    </FormProvider>
  );
}

PatientSearchForm.propTypes = {
  setFormField: yup.mixed().callback().required().pt(),
  disabled: yup.boolean().pt(),
};
