import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { cloneElement, useCallback } from 'react';
import { createPortal } from 'react-dom';
import toast, { Toaster } from 'react-hot-toast';
import { IconButton } from './icon-button';
import { yup } from '../utils';
// eslint-disable-next-line import/no-unresolved
import SoopaSmile from 'jsx:../../assets/soopa-smile.svg';
import { useTrackException } from './application-insights-provider';

export function ToastPortal() {
  return createPortal(<Toaster position="top-right" />, document.body);
}

export function triggerToast(type, element, options = {}) {
  toast.custom(
    (instance) => {
      instance.close = () => toast.dismiss(instance.id);
      return (
        <Toast instance={instance} type={type}>
          {cloneElement(element, { instance })}
        </Toast>
      );
    },
    { duration: 10000, ...options, style: {}, className: '' },
  );
}

export function successToast(element, options = {}) {
  triggerToast('success', element, { duration: 10000, ...options });
}

export function errorToast(element, options = {}) {
  triggerToast('error', element, options);
}

export const TOAST_INSTANCE_SCHEMA = yup.object({
  visible: yup.boolean().required(),
  close: yup.mixed().callback().required(),
});

export function Toast({ instance, type, children }) {
  return (
    <Transition
      appear={true}
      show={instance.visible}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
    >
      <div className="flex-1 w-0 p-3 flex flex-row items-start">
        <div className="flex-shrink-0 flex-grow-0">
          <ToastTypeIcon type={type} />
        </div>
        <div className="ml-3 flex-1">{children}</div>
        <div className="flex-shrink-0 flex-grow-0">
          <IconButton label="Close" icon={<XMarkIcon />} size="normal" onClick={instance.close} />
        </div>
      </div>
    </Transition>
  );
}

Toast.propTypes = {
  type: yup.string().oneOf(['success', 'error', 'soopa']).pt(),
  instance: TOAST_INSTANCE_SCHEMA.required().pt(),
  children: yup.mixed().react().pt(),
};

function ToastTypeIcon({ type }) {
  switch (type) {
    case 'success':
      return <CheckCircleIcon className="w-6 h-6 text-green-500" />;
    case 'error':
      return <ExclamationCircleIcon className="w-6 h-6 text-error-500" />;
    case 'soopa':
      return <SoopaSmile className="w-6 h-6 text-smile" />;
    default:
      return null;
  }
}

ToastTypeIcon.propTypes = {
  type: Toast.propTypes.type,
};

export function DefaultToastContent({ title, description }) {
  return (
    <>
      <p className="font-medium text-gray-900">{title}</p>
      <p className="mt-2 text-sm text-gray-500">{description}</p>
    </>
  );
}

DefaultToastContent.propTypes = {
  title: yup.string().required().pt(),
  description: yup.string().required().pt(),
};

export function useGenericErrorsToast() {
  const trackException = useTrackException();
  return useCallback(
    (title, error) => {
      if (error) {
        trackException(error);
      }

      let content;
      if (error?.message?.startsWith('NetworkError')) {
        content = <NetworkErrorContent />;
      } else {
        content = <UnexpectedErrorContent />;
      }
      return errorToast(
        <>
          <p className="font-medium text-gray-900">{title}</p>
          <p className="mt-2 text-sm text-gray-500">{content}</p>
        </>,
        { duration: 60000 },
      );
    },
    [trackException],
  );
}

function UnexpectedErrorContent() {
  return (
    <>
      An unexpected error occurred. Please try again. If the situation persists please contact{' '}
      <a href="mailto:support@soopa.co" className="underline hover:bg-red">
        support@soopa.co
      </a>
      .
    </>
  );
}

function NetworkErrorContent() {
  return 'There seems to be a problem with your internet connection. Please try again when your connection to the internet has been restored.';
}
