import { FormProvider, useForm } from 'react-hook-form';
import { SidePanelContent, SidePanelTitle } from '../../../lib/components/side-panel-outlet';
import { urlJoin, yup } from '../../../lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../lib/components/button';
import { TextField } from '../../../lib/components/form/text-field';
import { useCallback, useMemo } from 'react';
import { useIsSearchingAppointments, useSearchAppointments } from '../../../lib/api/store/search';
import { Tabs } from '../../../lib/components/tabs';
import { LoadingSpinner } from '../../../lib/components/loading-spinner';
import { resolvePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DateTime } from '../../../lib/components/date-time';
import { APPOINTMENT_SCHEMA } from '../../../lib/api/schemas';
import { PractitionerAvatar } from '../../../lib/components/avatar';
import { useNow } from '../../../lib/hooks/use-now';
import { Table, Td, Th } from '../../../lib/components/table';

const SEARCH_APPOINTMENT_FORM_SCHEMA = yup.object().shape({
  searchTerm: yup.string(),
});

const SEARCH_APPOINTMENT_FORM_DEFAULTS = {
  searchTerm: '',
};

export function SearchAppointment() {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('term');
  const location = useLocation();
  const navigate = useNavigate();

  const searchAppointmentForm = useForm({
    resolver: yupResolver(SEARCH_APPOINTMENT_FORM_SCHEMA),
    mode: 'onChange',
    defaultValues: { ...SEARCH_APPOINTMENT_FORM_DEFAULTS, searchTerm },
  });

  const isSearching = useIsSearchingAppointments();
  const currentSearchTerm = searchAppointmentForm.watch('searchTerm');
  const isChanged = currentSearchTerm !== searchTerm;

  const { isSubmitting, isValid, isLoading } = searchAppointmentForm.formState;
  const disabled =
    currentSearchTerm?.length === 0 ||
    !isValid ||
    isSubmitting ||
    isLoading ||
    !isChanged ||
    isSearching;

  const onSubmit = useCallback(
    ({ searchTerm }) => {
      const params = new URLSearchParams(location.search);
      params.set('term', searchTerm);
      navigate(urlJoin(location.pathname, params));
    },
    [location.pathname, location.search, navigate],
  );

  return (
    <FormProvider {...searchAppointmentForm}>
      <SidePanelContent
        header={<SidePanelTitle title="Search for an appointment" variant="fill" />}
      >
        <div className="flex flex-col justify-stretch h-full w-full">
          <form
            onSubmit={searchAppointmentForm.handleSubmit(onSubmit)}
            className="m-0 mb-5 grow-0 p-5 space-x-3 w-full justify-stretch grid grid-cols-[1fr_6em] md:grid-cols-[1fr_6em_25%] lg:grid-cols-[1fr_6em_50%] items-center border-b border-gray-200 bg-white"
          >
            <TextField
              field="searchTerm"
              placeholder="Enter an NHI number, name, phone or email..."
              disabled={isSubmitting || isLoading}
              autoFocus
            />
            <Button type="submit" label="Search" disabled={disabled} testId="submit-search" />
          </form>
          {searchTerm?.length > 0 ? <SearchAppointmentResults searchTerm={searchTerm} /> : null}
        </div>
      </SidePanelContent>
    </FormProvider>
  );
}

SearchAppointment.propTypes = {
  closeRoute: yup.string().required().pt(),
};

function SearchAppointmentResults({ searchTerm }) {
  const state = useSearchAppointments(searchTerm);
  const now = useNow();
  const { upcoming, past } = useMemo(() => {
    const results = Object.values(state.data);
    return {
      upcoming: results
        .filter((x) => x.list.dateTimestamp >= now.valueOf())
        .toSorted((a, b) => a.list.dateTimestamp - b.list.dateTimestamp),
      past: results
        .filter((x) => x.list.dateTimestamp < now.valueOf())
        .toSorted((a, b) => b.list.dateTimestamp - a.list.dateTimestamp),
    };
  }, [now, state.data]);

  return state.suspend ? (
    <div className="text-gray-400 grow flex justify-center items-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="w-full px-5 pb-5">
      <Tabs>
        <Tabs.Panel label={<SearchTabTitle label="Upcoming" count={upcoming.length} />}>
          <SearchResults data={upcoming} />
        </Tabs.Panel>
        <Tabs.Panel label={<SearchTabTitle label="Past" count={past.length} />}>
          <SearchResults data={past} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

SearchAppointmentResults.propTypes = {
  searchTerm: yup.string().required().pt(),
};

function SearchTabTitle({ label, count }) {
  return (
    <span>
      {label} ({count})
    </span>
  );
}

SearchTabTitle.propTypes = {
  label: yup.string().required().pt(),
  count: yup.number().required().pt(),
};

function SearchResults({ data }) {
  return (
    <Table className="grid-cols-[2fr_2fr_2fr_2fr_2fr_3fr_4fr_1.5fr]">
      <thead className="contents">
        <tr className="contents">
          <Th>Date</Th>
          <Th>NHI</Th>
          <Th>Family name</Th>
          <Th>Given name</Th>
          <Th>Phone</Th>
          <Th>Email</Th>
          <Th>Procedure</Th>
          <Th className="pl-0 text-center">Specialist</Th>
        </tr>
      </thead>
      <tbody className="contents">
        {data.map((appointment) => (
          <SearchResultRow key={appointment.id} appointment={appointment} />
        ))}
      </tbody>
    </Table>
  );
}

SearchResults.propTypes = {
  data: yup.array().of(yup.object()).required().pt(),
  filter: yup.mixed().callback().pt(),
};

function SearchResultRow({ appointment }) {
  const navigate = useNavigate();
  const location = useLocation();
  const gotoList = useCallback(() => {
    const params = new URLSearchParams(location.search);
    params.delete('term');
    params.set('highlight', appointment.slot.id);
    const resolved = resolvePath(`../details/${appointment.list.id}`, location.pathname);
    navigate(urlJoin(resolved.pathname, params));
  }, [appointment.list.id, appointment.slot.id, location.pathname, location.search, navigate]);
  return (
    <tr key={appointment.id} onClick={gotoList} className="contents group cursor-pointer">
      <Td className="h-20">
        <div>
          <DateTime t={appointment.list.dateTimestamp} format="dddd" className="block" />
          <DateTime t={appointment.list.dateTimestamp} format="D MMM YYYY" className="block" />
        </div>
      </Td>
      <Td className="h-20 uppercase">{appointment.patient?.nhiNumber}</Td>
      <Td className="h-20 uppercase">{appointment.patient?.nameFamily}</Td>
      <Td className="h-20">{appointment.patient?.nameGiven}</Td>
      <Td className="h-20">
        {appointment.patient.mobileNumber ?? appointment.patient.phoneNumber}
      </Td>
      <Td className="h-20">{appointment.patient.email}</Td>
      <Td className="h-20">
        <span className="whitespace-normal">
          {appointment.procedure?.procedureCode?.shortDisplay}
        </span>
      </Td>
      <Td className="h-20">
        <div className="flex flex-col items-center justify-center size-14 space-y-1 mx-auto">
          <PractitionerAvatar practitioner={appointment.practitioner} className="size-8" />
          <span className="truncate max-w-full text-sm">{appointment.practitioner.nameText}</span>
        </div>
      </Td>
    </tr>
  );
}

SearchResultRow.propTypes = {
  appointment: APPOINTMENT_SCHEMA.required().pt(),
};
