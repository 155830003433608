import { Children, cloneElement, forwardRef, useCallback, useMemo } from 'react';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { yup } from '../../utils';
import { ErrorMessage } from './error-message';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const RADIO_GROUP_STATUS_PROPS = {
  disabled: yup.boolean().pt(),
};

export function RadioGroup({ field, ...props }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={field}
      control={control}
      render={({ field }) => {
        return <RadioGroupInternal {...props} {...field} />;
      }}
    />
  );
}

RadioGroup.propTypes = {
  field: yup.string().required().pt(),
};

const RadioGroupInternal = forwardRef(function RadioGroupInternal({
  name,
  label = '',
  disabled = false,
  error = '',
  options = [],
  serde = undefined,
  value,
  onChange,
  onBlur,
}) {
  const hasLabel = label.length > 0;

  const serializedValue = useMemo(
    () => (typeof serde?.serialize === 'function' ? serde.serialize(value) : value),
    [serde, value],
  );

  const deserializeOnChange = useCallback(
    (value) =>
      onChange(typeof serde?.deserialize === 'function' ? serde.deserialize(value) : value),
    [serde, onChange],
  );

  const radioOptions = useMemo(
    () =>
      options.map((option) => {
        return (
          <HeadlessRadioGroup.Option
            key={option.key}
            value={option.key}
            className="min-w-[75px] cursor-pointer focus-visible:outline-none"
            data-testid={option.props['data-testid'] ?? `${name}-${option.key.toLowerCase()}`}
          >
            {({ disabled }) => cloneElement(option, { disabled })}
          </HeadlessRadioGroup.Option>
        );
      }),
    [name, options],
  );

  return (
    <HeadlessRadioGroup
      value={serializedValue}
      onChange={deserializeOnChange}
      onBlur={onBlur}
      name={undefined}
      disabled={disabled}
      data-testid={`radio-group-${name}`}
    >
      <HeadlessRadioGroup.Label
        className={clsx('flex justify-between', hasLabel ? 'mb-1' : 'hidden')}
      >
        <span className="text-sm font-medium leading-6 text-gray-900">{label}</span>
      </HeadlessRadioGroup.Label>
      <div className="flex flex-wrap flex-row justify-start items-center gap-x-1.5 gap-y-2.5">
        {radioOptions}
      </div>
      <ErrorMessage error={error} />
    </HeadlessRadioGroup>
  );
});

RadioGroupInternal.propTypes = {
  name: yup.string().required().pt(),
  label: yup.string().pt(),
  disabled: yup.boolean().pt(),
  options: yup.mixed().react().pt(),
  error: yup.string().pt(),
  serde: yup
    .object({
      deserialize: yup.mixed().callback(),
    })
    .pt(),
  value: yup.mixed().pt(),
  onChange: yup.mixed().callback().pt(),
  onBlur: yup.mixed().callback().pt(),
};

function RadioGroupItem({ disabled, children }) {
  const child = cloneElement(Children.only(children), { disabled });
  return (
    <div
      className={clsx(
        disabled
          ? 'bg-gray-50 text-gray-500 ring-gray-200 cursor-not-allowed'
          : 'bg-white border-gray-300 cursor-pointer',
        'relative rounded-lg border px-2 py-1.5 shadow-sm focus:outline-none ui-active:bg-indigo-50 ui-active:border-indigo-600 ui-active:ring-1 ui-active:ring-indigo-600 ui-checked:bg-indigo-50 ui-checked:border-indigo-400',
      )}
    >
      <div className={disabled ? 'opacity-50' : null}>{child}</div>
    </div>
  );
}

RadioGroupItem.propTypes = {
  ...RADIO_GROUP_STATUS_PROPS,
  children: yup.mixed().react().pt(),
  color: yup.string().oneOf(['indigo']).pt(),
};

function RadioGroupLabelItem({ label, description, disabled }) {
  const hasDescription = description?.length > 0;
  return (
    <RadioGroupItem disabled={disabled}>
      <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-2 items-center p-[1px]">
        <RadioGroupCheckMark />
        <HeadlessRadioGroup.Label
          as="span"
          className="block text-sm font-medium text-gray-900 leading-none"
        >
          {label}
        </HeadlessRadioGroup.Label>
        <HeadlessRadioGroup.Description
          as="span"
          className={clsx('text-xs text-gray-500 leading-none col-start-2', {
            hidden: !hasDescription,
          })}
        >
          {description}
        </HeadlessRadioGroup.Description>
      </div>
    </RadioGroupItem>
  );
}

RadioGroupLabelItem.propTypes = {
  ...RADIO_GROUP_STATUS_PROPS,
  label: yup.string().required().pt(),
  description: yup.string().required().pt(),
};

function RadioGroupCheckMark({ color = 'indigo' }) {
  let style;
  switch (color) {
    case 'orange':
      style = 'text-orange-600';
      break;
    default:
      style = 'text-indigo-600';
      break;
  }
  return (
    <span
      className={clsx(
        style,
        'bg-transparent ring-1 ring-inset ring-offset-0 ring-gray-300 ui-active:ring-0 ui-checked:ring-0 m-0 p-0 cursor-pointer rounded-full',
      )}
      aria-hidden="true"
    >
      <CheckCircleIcon className="size-5 invisible ui-checked:visible" />
    </span>
  );
}

RadioGroupCheckMark.propTypes = {
  color: yup.string().oneOf(['indigo', 'orange']).pt(),
};

RadioGroup.Item = RadioGroupItem;
RadioGroup.LabelItem = RadioGroupLabelItem;
RadioGroup.CheckMark = RadioGroupCheckMark;

const LOADING_OPTIONS = [<RadioGroupLabelItem key="loading" label="Loading" />];

export function LoadingRadioGroup(radioGroupProps) {
  return <RadioGroup {...radioGroupProps} options={LOADING_OPTIONS} disabled={true} />;
}
