import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  INITIAL_THUNK_STATE,
  useStateWithSuspendFlag,
  createAsyncApiThunk,
  useSharedDispatch,
} from './utils';
import { logger } from '../../logger';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const fetchEnum = createAsyncApiThunk('enums/fetch', async (name, { apiGet }) => {
  const values = await apiGet(`typesystem/getenumvalues/${name}`);
  logger.info(`Received ${values.length} ${name} values`);
  return values.reduce((acc, val) => ({ ...acc, [val]: val }), {});
});

const enumsSlice = createSlice({
  name: 'enums',
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEnum.pending, (state, action) => {
        if (!Object.hasOwn(state, action.meta.arg)) {
          state[action.meta.arg] = { ...INITIAL_THUNK_STATE };
        }
        const slice = state[action.meta.arg];
        slice.status = 'loading';
        slice.error = undefined;
        slice.t = Date.now();
      })
      .addCase(fetchEnum.fulfilled, (state, action) => {
        const slice = state[action.meta.arg];
        slice.status = 'succeeded';
        slice.data = action.payload;
        slice.error = undefined;
        slice.loaded = true;
        slice.t = Date.now();
      })
      .addCase(fetchEnum.rejected, (state, action) => {
        const slice = state[action.meta.arg];
        slice.status = 'failed';
        slice.error = action.error;
        slice.t = Date.now();
      });
  },
});

export default enumsSlice.reducer;

const enumSelector = (state) => state.enums;

export function useEnumState(name) {
  const { t } = useTranslation();
  useSharedDispatch(fetchEnum, name, { pollInterval: 2 * 3600 * 1000 });
  const state = useSelector(enumSelector);
  const enumState = useMemo(() => {
    const enumState = state[name] ?? { ...INITIAL_THUNK_STATE };
    return {
      ...enumState,
      data: Object.entries(enumState.data).reduce((acc, [k, v]) => {
        acc[k] = t(`enums.${name}.${v}`);
        return acc;
      }, {}),
    };
  }, [name, state, t]);
  return useStateWithSuspendFlag(enumState);
}

export function useEnum(name) {
  const enumState = useEnumState(name);
  return enumState.data;
}
