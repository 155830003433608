import { useFormContext, useWatch } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { yup, dayjs } from '../../../lib/utils';
import { Button } from '../../../lib/components/button';
import { RadioGroup } from '../../../lib/components/form/radio-group';
import { AvailabilityDots } from '../../../lib/components/availability-dots';
import { useToday } from '../../../lib/hooks/use-today';
import { TIMEZONE } from '../../../lib/constants';
import { DateTime } from '../../../lib/components/date-time';
import { useCalendarDayAvailability } from '../../../lib/api/store/availability';
import { useCallback, useMemo } from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useDayjs } from '../../../lib/hooks/use-dayjs';
import { LoadingSpinner } from '../../../lib/components/loading-spinner';

export function ProcedureFormActions({ label, submitHandler, children }) {
  const { onClose } = useOutletContext();
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isLoading },
  } = useFormContext();
  return (
    <form
      id="new-procedure-form"
      onSubmit={submitHandler ? handleSubmit(submitHandler) : null}
      className="m-0 flex flex-nowrap w-full flex-row flex-0 justify-end space-x-4 px-4 sm:px-6 py-6 border-t border-gray-200 bg-white"
    >
      <Button
        variant="outline"
        color="gray"
        label="Cancel"
        className="min-w-16"
        onClick={onClose}
      />
      <div className="hidden has-[div]:flex grow-[999] justify-end">{children}</div>
      <Button
        type="submit"
        form="new-procedure-form"
        label={label}
        className="min-w-16"
        disabled={submitHandler ? !isValid || isSubmitting || isLoading : true}
      />
    </form>
  );
}

ProcedureFormActions.propTypes = {
  label: yup.string().required().pt(),
  submitHandler: yup.mixed().callback().pt(),
  children: yup.mixed().react().required().pt(),
};

export function FormSection({ title, subtitle = null, children }) {
  return (
    <div className="grid grid-cols-[180px_1fr] gap-y-5 items-center border-b border-gray-100 p-5 last:border-b-0">
      <div className="py-2 self-start">
        <label className="text-gray-600 text-sm font-medium">{title}</label>
        {subtitle}
      </div>
      {children}
    </div>
  );
}

FormSection.propTypes = {
  title: yup.string().pt(),
  subtitle: yup.mixed().react().pt(),
  children: yup.mixed().react().required().pt(),
};

function useSelectedCalendarDayAvailability(date) {
  const [procedureCodeId, referralSource, practitionerId, listType] = useWatch({
    name: ['procedureCodeId', 'referralSource', 'practitionerId', 'listType'],
  });
  const availabilityState = useCalendarDayAvailability(
    date,
    procedureCodeId,
    referralSource,
    practitionerId,
    listType,
  );
  return availabilityState;
}

const JSON_SERDE = {
  serialize: JSON.stringify,
  deserialize: JSON.parse,
};

export function AvailableSlotGroups() {
  const selectedDateValue = useWatch({ name: 'selectedDate' });
  const selectedDate = useDayjs(selectedDateValue);
  const availabilityState = useSelectedCalendarDayAvailability(selectedDate);

  // Create a map of all the available slots
  const availableSlots = useMemo(
    () =>
      Object.values(availabilityState.data?.practitioners ?? {})
        .flatMap((practitioner) =>
          Object.entries(practitioner.availableCalendarSlots).map(([timestampString, slot]) => ({
            listId: practitioner.listId,
            calendarSlotId: slot.calendarSlotId,
            arrivalTimestamp: Number(timestampString),
            ruleViolations: slot.ruleViolations,
          })),
        )
        .reduce((acc, val) => {
          acc[val.calendarSlotId] = val;
          return acc;
        }, {}),
    [availabilityState.data?.practitioners],
  );

  const radioGroups = useMemo(
    () =>
      Object.values(availabilityState.data?.practitioners ?? {})
        .toSorted(sortPractitionersPredicate)
        .map((practitioner) => (
          <RadioGroup
            key={practitioner.id}
            field="calendarSlotDetails"
            label={practitioner.nameText}
            options={Object.values(practitioner.availableCalendarSlots)
              .map((slot) => availableSlots[slot.calendarSlotId])
              .toSorted((a, b) => a.arrivalTimestamp - b.arrivalTimestamp)
              .map((availableSlot) => (
                <SlotRadioGroupItem
                  key={JSON.stringify(availableSlot)}
                  label={dayjs(availableSlot.arrivalTimestamp).tz(TIMEZONE).format('HH:mm')}
                  warning={availableSlot.ruleViolations.length}
                  data-testid={`slot-${availableSlot.calendarSlotId}`}
                />
              ))}
            serde={JSON_SERDE}
          />
        )),
    [availabilityState.data?.practitioners, availableSlots],
  );
  if (availabilityState.suspend) {
    return (
      <div className="text-gray-600">
        <LoadingSpinner />
      </div>
    );
  } else if (radioGroups.length === 0) {
    return <div className="text-sm place-self-start italic pt-2">No slots available</div>;
  } else {
    return <div className="pt-2 space-y-2">{radioGroups}</div>;
  }
}

function SlotRadioGroupItem({ label, warning = false }) {
  return (
    <div
      className={clsx(
        warning
          ? 'bg-white border-orange-400 ui-active:bg-orange-50 ui-active:border-orange-600 ui-active:ring-orange-600 ui-checked:bg-orange-50 ui-checked:border-orange-400'
          : 'bg-white border-gray-300 ui-active:bg-indigo-50 ui-active:border-indigo-600 ui-active:ring-indigo-600 ui-checked:bg-indigo-50 ui-checked:border-indigo-400',
        'relative rounded-lg border min-w-[5.5em] px-2 py-1.5 shadow-sm focus:outline-none ui-active:ring-1',
      )}
    >
      <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-2 items-center p-[1px]">
        <RadioGroup.CheckMark color={warning ? 'orange' : 'indigo'} />
        <HeadlessRadioGroup.Label
          as="span"
          className="block text-sm font-medium leading-none text-gray-900"
        >
          {label}
        </HeadlessRadioGroup.Label>
      </div>
    </div>
  );
}

SlotRadioGroupItem.propTypes = {
  label: yup.string().required().pt(),
  warning: yup.boolean().pt(),
};

const minKey = (obj) => Object.keys(obj).reduce((acc, val) => Math.min(acc, Number(val)));

function sortPractitionersPredicate(a, b) {
  const startA = minKey(a.availableCalendarSlots);
  const startB = minKey(b.availableCalendarSlots);
  const dt = startA - startB;
  const di = a.nameText.localeCompare(b.nameText);
  return dt !== 0 ? dt : di;
}

export function CalendarDayAvailability({ day }) {
  const today = useToday();
  const { setValue } = useFormContext();
  const availabilityState = useSelectedCalendarDayAvailability(day);
  const selectedDateValue = useWatch({ name: 'selectedDate' });
  const selectedDate = useDayjs(selectedDateValue);
  const setSelectedCalendarDay = useCallback(() => {
    setValue('selectedDate', day.valueOf(), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue('calendarSlotDetails', null, {
      shouldValidate: true,
    });
  }, [day, setValue]);
  const commonClasses =
    'flex-col mx-auto flex w-12 h-8 items-center justify-center rounded-lg text-center relative';
  if (day.isSame(selectedDate, 'day')) {
    return (
      <div className={clsx(commonClasses, 'bg-indigo-500 text-white')}>
        <DateTime t={day} format="D" />
      </div>
    );
  } else if (day.isSame(today, 'day')) {
    return (
      <div className={clsx(commonClasses, 'font-semibold text-indigo-600')}>
        <DateTime t={day} format="D" />
      </div>
    );
  } else if (day.isBefore(today) || !availabilityState.data) {
    return (
      <div className={clsx(commonClasses, 'text-gray-300')}>
        <DateTime t={day} format="D" />
      </div>
    );
  } else {
    return (
      <button
        onClick={setSelectedCalendarDay}
        className={clsx(commonClasses, 'hover:bg-gray-300')}
        type="button"
        data-testid={`day-${day.format('D')}`}
      >
        <DateTime t={day} format="D" />
        <div className="absolute bottom-0 pb-1">
          <AvailabilityDots availability={availabilityState.data?.summary} />
        </div>
      </button>
    );
  }
  //
}

CalendarDayAvailability.propTypes = {
  day: yup.mixed().dayjs().required().pt(),
};

export function SelectedSlotDetails() {
  const calendarSlotDetails = useWatch({ name: 'calendarSlotDetails' });
  const arrivalTimestamp = calendarSlotDetails?.arrivalTimestamp;
  const ruleViolations = calendarSlotDetails?.ruleViolations;
  return arrivalTimestamp ? (
    <div className="text-sm mt-2 flex pr-2 max-w-full">
      <div className="mr-2">
        <span className="flex text-gray-600 font-bold">
          <ClockIcon className="h-5 w-5" />
        </span>
      </div>
      <div className="flex flex-col">
        <span className="flex text-gray-600 font-bold">
          <DateTime t={arrivalTimestamp} format="ddd, DD MMM YYYY [at] HH:mm" className="" />
        </span>
        <ul className="text-orange-400 font-medium">
          {ruleViolations.map((text) => (
            <li key="text" className="mt-1">
              {text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
}
