import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useMoveAppointment() {
  const dispatch = useDispatch();
  const moveAppointmentState = useApiPost('appointment/moveslot');
  return useMemo(
    () => ({
      ...moveAppointmentState,
      execute: async ({
        appointmentId,
        calendarSlotId,
        cancellationTime,
        cancellationReason,
        cancellationNotes,
      }) => {
        const body = {
          appointmentId,
          newSlotId: calendarSlotId,
          cancellationTime,
          cancellationReason,
          cancellationNotes,
        };
        const updatedCalendarSlots = await moveAppointmentState.execute(body);
        let result;
        for (const { calendarListId: listId, ...calendarSlot } of updatedCalendarSlots) {
          dispatch(calendarActions.addCalendarSlot({ listId, calendarSlot }));
          if (calendarSlot.id === body.newSlotId) {
            result = calendarSlot;
          }
        }
        return result;
      },
    }),
    [dispatch, moveAppointmentState],
  );
}
