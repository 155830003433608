/* global process */

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decodeJwt } from 'jose';

const initialState = {
  provider: undefined,
  token: undefined,
  // Allow specifying an API token from the command line during development to
  // skip login.
  // eslint-disable-next-line no-undef
  soopaAccessToken: process.env.SOOPA_API_TOKEN,
  soopaRefreshToken: undefined,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthentication(state, action) {
      if (action.payload) {
        const { provider, token, soopaAccessToken, soopaRefreshToken } = action.payload;
        state.provider = provider ?? state.provider;
        state.token = token ?? state.token;
        state.soopaAccessToken = soopaAccessToken ?? state.soopaAccessToken;
        state.soopaRefreshToken = soopaRefreshToken ?? state.soopaRefreshToken;
      } else {
        state = initialState;
      }
    },
  },
});

export default authenticationSlice.reducer;

export const { setAuthentication } = authenticationSlice.actions;

const AUTH_PROVIDER_STORAGE_KEY = 'soopa-auth-provider';

export function useSetAuthenticationDetails() {
  const dispatch = useDispatch();
  return useCallback(
    (details) => {
      if (details.provider?.length > 0) {
        try {
          localStorage.setItem(AUTH_PROVIDER_STORAGE_KEY, details.provider);
        } catch {
          // Can happen in private window, not an error.
        }
      }
      dispatch(setAuthentication(details));
    },
    [dispatch],
  );
}

export function useResetAuthenticationDetails() {
  const dispatch = useDispatch();
  return useCallback(() => {
    try {
      localStorage.removeItem(AUTH_PROVIDER_STORAGE_KEY);
    } catch {
      // Can happen in private window, not an error.
    }
    dispatch(setAuthentication(undefined));
  }, [dispatch]);
}

export function usePreviousAuthProvider() {
  const [value] = useState(() => {
    try {
      return localStorage.getItem(AUTH_PROVIDER_STORAGE_KEY);
    } catch {
      // Can happen in private window, not an error.
    }
  });
  const resetValue = useCallback(() => {
    try {
      localStorage.removeItem(AUTH_PROVIDER_STORAGE_KEY);
    } catch {
      // Can happen in private window, not an error.
    }
  }, []);
  return [value, resetValue];
}

const authenticationSelector = (state) => state.authentication;

export const tokensSelector = createSelector(
  (state) => state.authentication,
  (authentication) => {
    try {
      return {
        provider: {
          encoded: authentication.token,
          decoded: authentication.token ? decodeJwt(authentication.token) : undefined,
        },
        soopa: {
          access: {
            encoded: authentication.soopaAccessToken,
            decoded: decodeJwt(authentication.soopaAccessToken),
          },
          refresh: authentication.soopaRefreshToken,
        },
      };
    } catch {
      return undefined;
    }
  },
);

export function tokenSelector(state) {
  return tokensSelector(state)?.soopa?.access;
}

export function useAuthenticationDetails() {
  return useSelector(authenticationSelector);
}

export function useAuthenticatedIdentity() {
  const details = useSelector(tokensSelector);
  return details?.soopa?.access?.decoded
    ? { ...details.provider.decoded, ...details.soopa.access.decoded }
    : undefined;
}

export function useIsAuthenticated() {
  const identity = useAuthenticatedIdentity();
  return !!identity;
}
