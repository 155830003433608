import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useCancelAppointmentState() {
  const dispatch = useDispatch();
  const cancelAppointmentState = useApiPost('appointment/cancelappointment');
  return useMemo(
    () => ({
      ...cancelAppointmentState,
      execute: async ({
        appointmentId,
        cancellationStatus,
        cancellationTime,
        cancellationReason,
        cancellationNotes,
      }) => {
        const body = {
          appointmentId,
          status: cancellationStatus,
          cancellationTime,
          cancellationReason,
          cancellationNotes,
        };
        const updatedCalendarSlot = await cancelAppointmentState.execute(body);
        const { calendarListId: listId, ...calendarSlot } = updatedCalendarSlot;
        dispatch(calendarActions.addCalendarSlot({ listId, calendarSlot }));
      },
    }),
    [dispatch, cancelAppointmentState],
  );
}
