import { useVersion } from '../api/store/health';

export function Version() {
  const { current } = useVersion();
  if (current) {
    return <span>Soopa {current}</span>;
  } else {
    return null;
  }
}
