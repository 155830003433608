import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { TextField } from '../../../lib/components/form/text-field';
import { TextareaField } from '../../../lib/components/form/textarea-field';
import { CheckBox } from '../../../lib/components/form/check-box';
import { RadioGroup } from '../../../lib/components/form/radio-group';
import { ListBox } from '../../../lib/components/form/list-box';
import { ComboBox } from '../../../lib/components/form/combo-box';
import { cloneElement, useId } from 'react';
import { yup } from '../../../lib/utils';

import { Button } from '../../../lib/components/button';
import { BeakerIcon, BoltIcon } from '@heroicons/react/20/solid';

import { CloudIcon, UserIcon } from '@heroicons/react/24/outline';
import {
  TOAST_INSTANCE_SCHEMA,
  successToast,
  useGenericErrorsToast,
} from '../../../lib/components/toast';

function Debug() {
  return (
    <div>
      <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 mb-4">
        Debug Page
      </h1>
      <p>
        A collection of various tests such as API calls, component tests such as form elements and
        whatever else the devs deem useful.
      </p>
      <ShadingTestSection />
      <ButtonTestSection />
      <TextFieldTest />
      <TextareaFieldTest />
      <CheckBoxTest />
      <RadioGroupTest />
      <ListBoxTest />
      <ComboBoxTest />
    </div>
  );
}

function ShadingTestSection() {
  return (
    <section className="mt=6">
      <h2 className="text-lg font-bold leading-tight tracking-tight text-gray-900 mt-10 mb-4">
        Shading
      </h2>
      <div className="flex space-x-4 text-xs uppercase">
        <div className="shaded-pink rounded p-2">Pink</div>
        <div className="shaded-pink rounded border p-2">Pink</div>
        <div className="shaded-red rounded p-2">Red</div>
        <div className="shaded-red rounded border p-2">Red</div>
        <div className="shaded-orange rounded p-2">Orange</div>
        <div className="shaded-orange rounded border p-2">Orange</div>
        <div className="shaded-green rounded p-2">Green</div>
        <div className="shaded-green rounded border p-2">Green</div>
        <div className="shaded-violet rounded p-2">Violet</div>
        <div className="shaded-violet rounded border p-2">Violet</div>
        <div className="shaded-blue rounded p-2">Blue</div>
        <div className="shaded-blue rounded border p-2">Blue</div>
        <div className="shaded-gray rounded p-2">Gray</div>
        <div className="shaded-gray rounded border p-2">Gray</div>
      </div>
    </section>
  );
}

function ButtonTestSection() {
  const showToast = () => successToast(<ToastContent />);
  const genericErrorToast = useGenericErrorsToast();
  return (
    <section className="mt=6 space-y-4">
      <h2 className="text-lg font-bold leading-tight tracking-tight text-gray-900 mt-10">
        &#60;Button /&#62;
      </h2>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Filled" onClick={showToast} size="small" />
        <Button
          label="Icon only"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
          size="small"
        />
        <Button label="Filled" onClick={showToast} />
        <Button label="Icon only" labelClassName="sr-only" icon={BoltIcon} onClick={showToast} />
        <Button label="Standard" disabled />
        <Button label="Icon only" labelClassName="sr-only" icon={BeakerIcon} disabled />
        <Button label="Standard" size="large" />
        <Button label="Zap" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button
          label="Outline"
          variant="outline"
          onClick={() => genericErrorToast('Test', new Error('test'))}
        />
        <Button
          label="Icon only"
          variant="outline"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="outline" disabled />
        <Button
          label="Icon only"
          variant="outline"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="outline" size="large" />
        <Button label="Zap" variant="outline" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Plain" variant="plain" onClick={showToast} />
        <Button
          label="Icon only"
          variant="plain"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="plain" disabled />
        <Button
          label="Icon only"
          variant="plain"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="plain" size="large" />
        <Button label="Zap" variant="plain" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Filled" color="gray" onClick={showToast} />
        <Button
          label="Icon only"
          color="gray"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" color="gray" disabled />
        <Button
          label="Icon only"
          color="gray"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" color="gray" size="large" />
        <Button label="Zap" color="gray" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Outline" variant="outline" color="gray" onClick={showToast} />
        <Button
          label="Icon only"
          variant="outline"
          color="gray"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="outline" disabled />
        <Button
          label="Icon only"
          variant="outline"
          color="gray"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="outline" color="gray" size="large" />
        <Button label="Zap" variant="outline" color="gray" icon={BoltIcon} disabled size="large" />
      </div>
      <div className="flex flex-row space-x-3 items-center">
        <Button label="Plain" variant="plain" color="gray" onClick={showToast} />
        <Button
          label="Icon only"
          variant="plain"
          color="gray"
          labelClassName="sr-only"
          icon={BoltIcon}
          onClick={showToast}
        />
        <Button label="Standard" variant="plain" color="gray" disabled />
        <Button
          label="Icon only"
          variant="plain"
          color="gray"
          labelClassName="sr-only"
          icon={BeakerIcon}
          disabled
        />
        <Button label="Standard" variant="plain" color="gray" size="large" />
        <Button label="Zap" variant="plain" color="gray" icon={BoltIcon} disabled size="large" />
      </div>
    </section>
  );
}

function ToastContent({ instance }) {
  return (
    <>
      <p className="font-medium text-gray-900">New booking for VRIES, Victor created!</p>
      <p className="mt-2 text-sm text-gray-500">
        Colonosopy & Gastroscopy with Dr. Ogra on Tue, 20 Sep 2023 at 15:00
      </p>
      <p className="mt-2 text-sm text-medium">
        <button className="text-indigo-600 hover:underline" onClick={instance.close}>
          View list
        </button>
      </p>
    </>
  );
}

ToastContent.propTypes = {
  instance: TOAST_INSTANCE_SCHEMA.required().pt(),
};

function FromElementTestSection({ element, defaultValues, className, children }) {
  const form = useForm({
    defaultValues,
  });
  const elementProps = form.watch();
  const testValue = elementProps['test'];
  delete elementProps['test'];

  return (
    <section className="mt=6">
      <h2 className="text-lg font-bold leading-tight tracking-tight text-gray-900 mt-10 mb-4">
        &#60;{element.type.name} /&#62;
      </h2>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => form.reset())}>
          <div className={className}>{cloneElement(element, elementProps)}</div>
          <div className="mt-4 p-2 flex-row space-x-2 bg-gray-200/50">{children}</div>
          <div className="p-2 bg-gray-200/50">
            <span className="pr-1">Current value:</span>
            <b>
              {testValue.toString()}
              {typeof testValue == 'boolean' && ' (boolean)'}
            </b>
          </div>
        </form>
      </FormProvider>
    </section>
  );
}

FromElementTestSection.propTypes = {
  element: yup.mixed().callback().required().pt(),
  defaultValues: yup.object().required().pt(),
  className: yup.string().pt(),
  children: yup.mixed().react().pt(),
};

function TextFieldTest() {
  return (
    <FromElementTestSection
      element={<TextField field="test" placeholder="Input your name here" />}
      defaultValues={{
        test: '',
        label: 'The label',
        help: 'You can set some guidelines for the user.',
        hint: 'Optional hint',
        disabled: false,
        error: '',
      }}
    >
      <TextFieldTestControls />
    </FromElementTestSection>
  );
}

function TextFieldTestControls() {
  const { register } = useFormContext();
  const id = useId();
  return (
    <>
      <input type="text" placeholder="Label" {...register('label')} />
      <input type="text" placeholder="Hint" {...register('hint')} />
      <input type="text" placeholder="Help text" {...register('help')} />
      <input type="checkbox" id={id} {...register('disabled')} />
      <label htmlFor={id}>Disabled</label>
      <input type="text" placeholder="Error message" {...register('error')} />
    </>
  );
}

function TextareaFieldTest() {
  return (
    <FromElementTestSection
      element={<TextareaField field="test" placeholder="Input your text here" />}
      defaultValues={{
        test: '',
        label: 'The label',
        help: 'You can set some guidelines for the user.',
        hint: 'Optional hint',
        disabled: false,
        error: '',
      }}
    >
      <TextareaFieldTestControls />
    </FromElementTestSection>
  );
}

function TextareaFieldTestControls() {
  const { register } = useFormContext();
  const id = useId();
  return (
    <>
      <input type="text" placeholder="Label" {...register('label')} />
      <input type="text" placeholder="Hint" {...register('hint')} />
      <input type="text" placeholder="Help text" {...register('help')} />
      <input type="checkbox" id={id} {...register('disabled')} />
      <label htmlFor={id}>Disabled</label>
      <input type="text" placeholder="Error message" {...register('error')} />
    </>
  );
}

function CheckBoxTest() {
  return (
    <FromElementTestSection
      element={<CheckBox field="test" />}
      defaultValues={{
        test: true,
        label: 'The label',
        description: 'Checkbox description',
      }}
    >
      <CheckBoxTestControls />
    </FromElementTestSection>
  );
}

function CheckBoxTestControls() {
  const { register } = useFormContext();
  const id = useId();
  return (
    <>
      <input type="text" placeholder="Label" {...register('label')} />
      <input type="text" placeholder="Description" {...register('description')} />
      <input type="checkbox" id={id} {...register('disabled')} />
      <label htmlFor={id}>Disabled</label>
    </>
  );
}

function RadioGroupTest() {
  return (
    <FromElementTestSection
      element={
        <RadioGroup
          field="test"
          options={[
            <RadioGroup.LabelItem
              key="option-a"
              label="Option A"
              description="This is maybe not the option for you"
            />,
            <RadioGroup.LabelItem
              key="option-b"
              label="Option B"
              description="Choose this because B stands for better"
            />,
            <RadioGroup.LabelItem key="option-c" label="Option C" />,
            <RadioGroup.Item key="jess">
              <div className="flex flex-col items-center w-14">
                <img
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  className="h-8 w-8 flex-shrink-0 rounded-full"
                  alt=""
                />
                <span className="mt-2 text-gray-900">Jess</span>
              </div>
            </RadioGroup.Item>,
          ]}
        />
      }
      defaultValues={{
        test: 'option-a',
        label: 'The label',
        disabled: false,
      }}
    >
      <RadioGroupTestControls />
    </FromElementTestSection>
  );
}

function RadioGroupTestControls() {
  const { register } = useFormContext();
  const id = useId();
  return (
    <>
      <input type="text" placeholder="Label" {...register('label')} />
      <input type="checkbox" id={id} {...register('disabled')} />
      <label htmlFor={id}>Disabled</label>
    </>
  );
}

function ListBoxTest() {
  return (
    <FromElementTestSection
      element={
        <ListBox
          field="test"
          placeholder="Select one of the options"
          options={[
            <ListBox.Item key="item-a">
              <div className="flex flex-row items-center">
                <img
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  className="h-6 w-6 flex-shrink-0 rounded-full"
                  alt=""
                />
                <span className="ml-2 text-gray-900">Jess</span>
              </div>
            </ListBox.Item>,
            <ListBox.LabelItem key="item-b" label="Item B" icon={CloudIcon} />,
          ]}
        />
      }
      defaultValues={{
        test: '',
        label: 'The label',
        disabled: false,
      }}
    >
      <ListBoxTestControls />
    </FromElementTestSection>
  );
}

function ListBoxTestControls() {
  const { register } = useFormContext();
  const id = useId();
  return (
    <>
      <input type="text" placeholder="Label" {...register('label')} />
      <input type="checkbox" id={id} {...register('disabled')} />
      <label htmlFor={id}>Disabled</label>
      <input type="text" placeholder="Error message" {...register('error')} />
      <button type="submit">Reset</button>
    </>
  );
}

function ComboBoxTest() {
  return (
    <FromElementTestSection
      element={
        <ComboBox
          field="test"
          placeholder="Starting typing to search..."
          options={names.map((name, index) => (
            <ComboBox.LabelItem key={index} label={name} icon={UserIcon} />
          ))}
        />
      }
      defaultValues={{
        test: '',
        label: 'The label',
        disabled: false,
      }}
    >
      <ComboBoxTestControls />
    </FromElementTestSection>
  );
}

function ComboBoxTestControls() {
  const { register } = useFormContext();
  const id = useId();
  return (
    <>
      <input type="text" placeholder="Label" {...register('label')} />
      <input type="checkbox" id={id} {...register('disabled')} />
      <label htmlFor={id}>Disabled</label>
      <input type="text" placeholder="Error message" {...register('error')} />
      <button type="submit">Reset</button>
    </>
  );
}

export const debugRoute = {
  path: 'debug',
  element: <Debug />,
};

const names = [
  'Cayden Todd',
  'Ellena Davila',
  'Josephine Grant',
  'Jazmin Ray',
  'Maria Nolan',
  'Angela Rosales',
  'Noah Schmidt',
  'Judith Simmons',
  'Harry Hatfield',
  'Addie Bishop',
  'Isha Proctor',
  'Ruben Beasley',
  'Aneesa Silva',
  'Ned Underwood',
  "Theresa O'Connor",
  'Jane Mendoza',
  'Antony Clark',
  'Kasey Diaz',
  'Raymond Mack',
  'Clara Avila',
  'Elif Allen',
  'Madiha Gill',
  'Rhodri Mendez',
  'Jonathan Graham',
  'Ashwin Huffman',
  'Betty Carrillo',
  'Leyton Bartlett',
  'Teresa Saunders',
  'Leyla Perez',
  'Chanelle Schaefer',
  'Owain Norris',
  'Roy Dickson',
  'Brogan Marsh',
  'Aliza Mitchell',
  'Mikey Bowers',
  'Amir Garza',
  'Steffan Sykes',
  'Shreya Meadows',
  'Alan Maldonado',
  'Hollie Cameron',
  'Rehan Harrell',
  'Muhammad Hawkins',
  'Bill Weeks',
  'Xanthe Hendrix',
  'Constance Hogan',
  'Felicity Miles',
  'Cai Hayden',
  'Abdul Booth',
  'Gordon Fischer',
  'Erik Hodge',
];
