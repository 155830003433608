import clsx from 'clsx';
import { useStatusColours } from '../hooks/use-status-colour';
import { yup } from '../utils';

export function ScoreBadge({ score }) {
  const style = useStatusColours(score.status);
  return (
    <div className={clsx(style, 'text-xs font-semibold px-[5px] py-[2px] rounded-lg')}>
      {score.value}
    </div>
  );
}

ScoreBadge.propTypes = {
  score: yup
    .object({
      status: yup
        .string()
        .transform((value) => (value === null ? undefined : value))
        .oneOf(['red', 'orange', 'green', 'black', 'none'])
        .default('none'),
      value: yup.number().required(),
    })
    .pt(),
};
