import { useCallback } from 'react';
import { SLOT_SCHEMA } from '../../api/schemas';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Td, TdArrivalTime, TdSlotNumber, APPOINTMENT_STATUSES } from './shared';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSaveAppointment } from '../../api/actions/save-appointment';
import { yup } from '../../utils';
import { EnumListBox } from '../enum-list-box';
import { ProcedureComboBox } from '../procedure-combo-box';
import { Button } from '../button';
import { useGenericErrorsToast } from '../toast';
import { PatientSearchForm } from './patient-search-form';
import { PatientDetailsFormFields } from './patient-details-form-fields';
import { APPOINTMENT_FORM_SCHEMA } from './appointment-form-schema';

export const ADD_APPOINTMENT_FORM_DEFAULTS = {
  patientId: undefined,
  nhiNumber: '',
  nameGiven: '',
  nameFamily: '',
  email: '',
  phoneNumber: '',
  referralSource: '',
  procedureCodeId: '',
  appointmentStatus: 'Booked',
  appointmentNote: '',
};

const OMIT_APPOINTMENT_STATUSES = [
  APPOINTMENT_STATUSES.CANCELLED,
  APPOINTMENT_STATUSES.ENTERED_IN_ERROR,
];

export function EmptySlotAddAppointment({ index, slot, setMode }) {
  const addAppointmentForm = useForm({
    resolver: yupResolver(APPOINTMENT_FORM_SCHEMA, { mode: 'sync' }),
    mode: 'onTouched',
    defaultValues: ADD_APPOINTMENT_FORM_DEFAULTS,
  });
  const genericErrorsToast = useGenericErrorsToast();
  const patientId = addAppointmentForm.watch('patientId');

  const { isSubmitting, isSubmitted, isValid, isLoading, errors } = addAppointmentForm.formState;
  const disabled = isSubmitting || isSubmitted || isLoading;

  const addAppointment = useSaveAppointment();
  const addAppointmentHandler = useCallback(
    async (values) => {
      try {
        await addAppointment.execute({
          calendarSlotId: slot.id,
          patientId: values.patientId,
          nhiNumber: values.nhiNumber,
          nameGiven: values.nameGiven,
          nameFamily: values.nameFamily,
          email: values.email,
          phoneNumber: values.phoneNumber,
          referralSource: values.referralSource,
          procedureCodeId: values.procedureCodeId,
          appointmentNote: values.appointmentNote,
          appointmentStatus: values.appointmentStatus,
        });
      } catch (error) {
        genericErrorsToast('Failed to add appointment', error);
      }
    },
    [addAppointment, genericErrorsToast, slot.id],
  );

  return (
    <FormProvider {...addAppointmentForm}>
      <tr className="group">
        <TdSlotNumber index={index} className="bg-gray-50" />
        <TdArrivalTime slot={slot} className="bg-gray-50" />
        <Td className="bg-gray-50">
          <EnumListBox
            name="AppointmentStatus"
            field="appointmentStatus"
            placeholder="Status"
            valuesToOmit={OMIT_APPOINTMENT_STATUSES}
            disabled={disabled}
          />
        </Td>
        <Td className="bg-gray-50">
          <ProcedureComboBox
            field="procedureCodeId"
            error={errors.procedureCodeId?.message}
            disabled={disabled}
          />
        </Td>
        <Td className="bg-gray-50"></Td>
        {patientId === undefined ? (
          <PatientSearchForm
            disabled={disabled}
            setFormField={(k, v) =>
              addAppointmentForm.setValue(k, v, { shouldValidate: true, shouldDirty: true })
            }
          />
        ) : (
          <PatientDetailsFormFields />
        )}
        <Td className="bg-gray-50">
          <EnumListBox
            name="ReferralSource"
            field="referralSource"
            placeholder="Source"
            disabled={disabled}
          />
        </Td>
        <Td colSpan={2} className="text-center bg-gray-50 pr-3">
          <div className="flex gap-x-1 items-center justify-end">
            <form onSubmit={addAppointmentForm.handleSubmit(addAppointmentHandler)} className="m-0">
              <Button type="submit" variant="plain" label="SAVE" disabled={disabled || !isValid} />
            </form>
            <Button
              icon={XMarkIcon}
              variant="plain"
              labelClassName="sr-only"
              onClick={() => setMode(0)}
              disabled={disabled}
            />
          </div>
        </Td>
      </tr>
    </FormProvider>
  );
}

EmptySlotAddAppointment.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  setMode: yup.mixed().callback().pt(),
};
