import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useUpdateSlotStatus() {
  const dispatch = useDispatch();
  const updateSlotStatus = useApiPost('calendar/updateslotstatus');
  return useMemo(
    () => ({
      ...updateSlotStatus,
      execute: async ({ calendarSlotId, status, reason }) => {
        const body = {
          slotId: calendarSlotId,
          status,
        };
        if (status !== 'Available') {
          body.reason = reason;
        }
        const updatedCalendarSlot = await updateSlotStatus.execute(body);
        const { calendarListId: listId, ...calendarSlot } = updatedCalendarSlot;
        dispatch(calendarActions.addCalendarSlot({ listId, calendarSlot }));
        return updatedCalendarSlot;
      },
    }),
    [updateSlotStatus, dispatch],
  );
}
