import { Fragment, createElement, forwardRef, useMemo } from 'react';
import { Listbox as HeadlessListbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { yup } from '../../utils';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorIcon, ErrorMessage } from './error-message';
import clsx from 'clsx';
import { flip, offset, useFloating } from '@floating-ui/react-dom';

function DefaultSelectedComponent({ value, options, placeholder }) {
  return useMemo(() => {
    const hasValue = !(
      value === undefined ||
      value === null ||
      ((Array.isArray(value) || typeof value === 'string') && value.length === 0)
    );
    if (hasValue) {
      const findOption = (key) => {
        return options.find((option) => option.key === key) ?? null;
      };
      if (Array.isArray(value)) {
        if (value.length === 1) {
          return findOption(value[0]);
        } else {
          return 'Multiple';
        }
      } else {
        return findOption(value);
      }
    } else {
      return <span className="opacity-50">{placeholder}</span>;
    }
  }, [options, placeholder, value]);
}

export function ListBox({ field, ...props }) {
  if (field?.length > 0) {
    return <ControlledListBox {...props} field={field} />;
  } else {
    return <UncontrolledListBox {...props} />;
  }
}

ListBox.propTypes = {
  field: yup.string().required().pt(),
};

function ControlledListBox({ field, ...props }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={field}
      control={control}
      render={({ field: inputProps }) => <UncontrolledListBox {...props} {...inputProps} />}
    />
  );
}

ControlledListBox.propTypes = {
  field: yup.string().required().pt(),
};

const UncontrolledListBox = forwardRef(function UncontrolledListBox({
  label = '',
  placeholder = '',
  error = '',
  disabled = false,
  options = [],
  multiple = false,
  SelectedComponent = DefaultSelectedComponent,
  ...props
}) {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(4), flip()],
  });
  const hasError = error.length > 0;
  return (
    <HeadlessListbox {...props} name={undefined} disabled={disabled} multiple={multiple}>
      {({ open }) => {
        const hasLabel = label.length > 0;
        const value = props.value; // eslint-disable-line react/prop-types
        return (
          <>
            <HeadlessListbox.Label
              className={clsx(
                'block text-sm font-medium leading-6 text-gray-900',
                hasLabel ? 'mb-1' : 'hidden',
              )}
            >
              {label}
            </HeadlessListbox.Label>
            <div ref={refs.setReference} className="relative listbox-holder">
              <HeadlessListbox.Button
                className={clsx(
                  'relative w-full flex flex-row cursor-default rounded-md bg-white py-1.5 pl-2.5 pr-[22] text-left shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 focus:ring-2 focus:outline-none overflow-hidden',
                  hasError
                    ? 'text-error-500 ring-error-500 focus:outline-none focus:ring-error-500 disabled:ring-error-500/50'
                    : 'text-gray-900 focus:ring-indigo-500 ring-gray-300 disabled:ring-gray-200',
                )}
              >
                <div className={clsx('truncate', disabled ? 'opacity-50' : null)}>
                  <SelectedComponent value={value} options={options} placeholder={placeholder} />
                </div>
                <span>&nbsp;</span>
                {error && (
                  <div className="relative w-3 h-5 -right-6 top-0.5">
                    <ErrorIcon error={error} />
                  </div>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </HeadlessListbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <HeadlessListbox.Options
                  ref={refs.setFloating}
                  style={floatingStyles}
                  className="z-20 max-h-56 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListBoxOptionsMemo options={options} />
                </HeadlessListbox.Options>
              </Transition>
            </div>
            <ErrorMessage error={error} />
          </>
        );
      }}
    </HeadlessListbox>
  );
});

UncontrolledListBox.propTypes = {
  field: yup.string().required().pt(),
  label: yup.string().pt(),
  placeholder: yup.string().pt(),
  disabled: yup.boolean().pt(),
  options: yup.mixed().react().pt(),
  error: yup.string().pt(),
  multiple: yup.boolean().pt(),
  SelectedComponent: yup.mixed().react().pt(),
};

function ListBoxOptionsMemo({ options }) {
  return useMemo(
    () =>
      options.map((option) =>
        option.type === ListBox.UnselectableItem ? (
          option
        ) : (
          <HeadlessListbox.Option
            key={option.key}
            className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 ui-active:bg-indigo-50"
            value={option.key}
            disabled={option.disabled}
          >
            {option}
          </HeadlessListbox.Option>
        ),
      ),
    [options],
  );
}

function ListBoxItem({ children }) {
  return (
    <>
      <div className="flex items-center truncate">{children}</div>
      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-indigo-600 invisible ui-selected:visible">
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      </span>
    </>
  );
}

ListBoxItem.propTypes = {
  children: yup.mixed().react().pt(),
};

function ListBoxLabelItem({ label, icon }) {
  return (
    <ListBoxItem>
      <div className="flex items-center truncate">
        {icon
          ? createElement(icon, {
              className: 'h-5 w-5 flex-shrink-0 mr-2',
              'aria-hidden': true,
            })
          : null}
        <span className="ui-selected:font-semibold font-normal block truncate">{label}</span>
      </div>
    </ListBoxItem>
  );
}

ListBoxLabelItem.propTypes = {
  label: yup.string().required().pt(),
  icon: yup.mixed().react().pt(),
};

function UnselectableListBoxItem({ children }) {
  return children;
}

UnselectableListBoxItem.propTypes = {
  children: yup.mixed().react().pt(),
};

ListBox.Item = ListBoxItem;
ListBox.LabelItem = ListBoxLabelItem;
ListBox.UnselectableItem = UnselectableListBoxItem;

export function LoadingListBox(props) {
  return <ListBox {...props} options={[]} disabled={true} placeholder="Loading" />;
}
