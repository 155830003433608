import { useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  INITIAL_THUNK_STATE,
  createAsyncApiThunk,
  assignHashAndKeys,
  transformArrayToMapping,
  useSharedDispatch,
  useStateWithSuspendFlag,
  setThunkStateFulfilled,
  setThunkStateRejected,
  setThunkStatePending,
  dateToKey,
} from './utils';
import { API_DATE_FORMAT, EMPTY_ARRAY, TIMEZONE } from '../../constants';
import invariant from 'tiny-invariant';
import { dayjs, yup } from '../../utils';
import { logger } from '../../logger';
import { slice as calendarSlice, fetchLists } from './calendar';
import { transformPractitioner } from './transformations';

const DAY_KEY_FORMAT = 'YYYY-MM-DD';
const DAY_KEY_REGEX = /^(20[0-9]{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const MONTH_KEY_FORMAT = 'YYYY-MM';
const MONTH_KEY_REGEX = /^(20[0-9]{2})-(0[1-9]|1[0-2])$/;

const LIST_RESPONSE_SCHEMA = yup
  .object({
    id: yup.string().guid().required(),
    date: yup.string().matches(DAY_KEY_REGEX).required(),
    listType: yup.string().required(),
    calendarSlots: yup
      .array()
      .of(
        yup.object({
          id: yup.string().guid().required(),
          isAcceptableSlot: yup.boolean().required(),
          acceptableSlotFailureReasons: yup.array().of(yup.string().required()).default([]),
        }),
      )
      .required(),
  })
  .required();

export const fetchAvailability = createAsyncApiThunk(
  'availability/fetch',
  async (arg, { apiPost }) => {
    invariant(MONTH_KEY_REGEX.test(arg.date), 'Expected a YYYY-MM key');
    invariant(arg.procedureCodeId?.length > 0, 'Expected a procedure code id');
    invariant(arg.referralSource?.length > 0, 'Expected a referral source');
    const start = dayjs(arg.date, MONTH_KEY_FORMAT).tz(TIMEZONE, true);
    const end = start.endOf('month');
    const { calendarAvailabilityLists: rawLists } = await apiPost('Calendar/GetAvailability', {
      startDate: start.format(API_DATE_FORMAT),
      endDate: end.format(API_DATE_FORMAT),
      procedureCodeId: arg.procedureCodeId,
      referralSource: arg.referralSource,
      practitionerId: null,
    });
    logger.info(`Received ${rawLists.length} availability lists`);
    return rawLists.map((rawList) => {
      LIST_RESPONSE_SCHEMA.validateSync(rawList);
      return assignHashAndKeys(
        { ...rawList, calendarSlots: transformArrayToMapping(rawList.calendarSlots) },
        extractKeysFromList,
      );
    });
  },
);

function argToStateKey(arg) {
  return `${arg.date}/${arg.procedureCodeId}/${arg.referralSource}`;
}

const availabilitySlice = createSlice({
  name: 'availability',
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAvailability.pending, (state, action) => {
        const key = argToStateKey(action.meta.arg);
        if (!Object.hasOwn(state, key)) {
          state[key] = { ...INITIAL_THUNK_STATE };
        }
        const slice = state[key];
        setThunkStatePending(slice);
      })
      .addCase(fetchAvailability.fulfilled, (state, action) => {
        const key = argToStateKey(action.meta.arg);
        const slice = state[key];
        setThunkStateFulfilled(slice, {});
        for (const list of action.payload) {
          if (!Object.hasOwn(slice.data, list.date)) {
            slice.data[list.date] = {};
          }
          for (const calendarSlot of Object.values(list.calendarSlots)) {
            slice.data[list.date][calendarSlot.id] = { ...calendarSlot, listId: list.id };
          }
        }
      })
      .addCase(fetchAvailability.rejected, (state, action) => {
        const key = argToStateKey(action.meta.arg);
        const slice = state[key];
        setThunkStateRejected(slice, action.error);
      });
  },
});

export default availabilitySlice.reducer;

export const actions = availabilitySlice.actions;

const availabilitySelector = createSelector(
  [
    availabilitySlice.selectSlice,
    calendarSlice,
    (_, monthKey) => monthKey,
    (_, __, procedureCodeId) => procedureCodeId,
    (_, __, ___, referralSource) => referralSource,
    (_, __, ___, ____, practitionerId) => practitionerId,
    (_, __, ___, ____, _____, listType) => listType,
  ],
  (
    availabilitySlice,
    calendarSlice,
    monthKey,
    procedureCodeId,
    referralSource,
    filterPractitionerId,
    filterListType,
  ) => {
    const key = argToStateKey({ date: monthKey, procedureCodeId, referralSource });
    const monthState = availabilitySlice[key] ?? { ...INITIAL_THUNK_STATE };
    return {
      ...monthState,
      data: Object.entries(monthState.data).reduce((acc, [date, calendarSlotMap]) => {
        for (const availabilitySlot of Object.values(calendarSlotMap)) {
          const maybeRawList = calendarSlice[availabilitySlot.listId];
          if (maybeRawList) {
            const rawCalendarSlot = maybeRawList.calendarSlots[availabilitySlot.id];
            const practitionerId = maybeRawList?.practitioner?.id;
            const listType = maybeRawList?.listType?.toLowerCase();
            if (
              rawCalendarSlot &&
              !rawCalendarSlot.appointment &&
              (filterPractitionerId === 'any' || filterPractitionerId === practitionerId) &&
              (filterListType === 'any' || filterListType.toLowerCase() === listType)
            ) {
              // Initialize state for this date
              if (!Object.hasOwn(acc, date)) {
                acc[date] = { summary: ['black', 'black'], practitioners: {} };
              }

              // Initialize state for this practitioner for this date
              if (!Object.hasOwn(acc[date].practitioners, practitionerId)) {
                acc[date].practitioners[practitionerId] = {
                  ...transformPractitioner(maybeRawList.practitioner),
                  listId: maybeRawList.id,
                  availableCalendarSlots: {},
                };
              }

              // Compute arrival timestamp
              const arrivalTime = dayjs
                .utc(`${maybeRawList.date}T${rawCalendarSlot.arrivalTime}Z`)
                .tz(TIMEZONE, true);

              // Get rule violations
              const ruleViolations =
                !availabilitySlot.isAcceptableSlot &&
                availabilitySlot.acceptableSlotFailureReasons?.length > 0
                  ? availabilitySlot.acceptableSlotFailureReasons
                  : EMPTY_ARRAY;

              // Set the availability dots summary
              const arrivalSlot = arrivalTime.hour() < 12 ? 0 : 1;
              if (ruleViolations.length === 0) {
                acc[date].summary[arrivalSlot] = 'green';
              } else if (acc[date].summary[arrivalTime.hour() < 12 ? 0 : 1] !== 'green') {
                acc[date].summary[arrivalSlot] = 'orange';
              }

              // Set the calendar slot but don't overwrite slot with the same
              // time (unless the slot number is lower). This way we only show
              // each time once on the new procedure form and we fill the first
              // slots first.
              const availableSlots = acc[date].practitioners[practitionerId].availableCalendarSlots;
              const slotTime = arrivalTime.valueOf();

              if (
                !Object.hasOwn(availableSlots, slotTime) ||
                ruleViolations < availableSlots[slotTime].ruleViolations ||
                rawCalendarSlot.slotNumber < availableSlots[slotTime].slotNumber
              ) {
                availableSlots[slotTime] = {
                  calendarSlotId: rawCalendarSlot.id,
                  ruleViolations,
                };
              }
            }
          }
        }
        return acc;
      }, {}),
    };
  },
);

export function useCalendarMonthAvailability(
  date,
  procedureCodeId,
  referralSource,
  practitionerId,
  listType,
) {
  // Only procedure code and referral source are sent to the api, filtering by
  // practitionerId and listType is done locally.
  const args = useMemo(
    () => ({
      date: dateToKey(date, MONTH_KEY_FORMAT),
      procedureCodeId,
      referralSource,
    }),
    [date, procedureCodeId, referralSource],
  );
  useSharedDispatch(fetchLists, args.date);
  useSharedDispatch(fetchAvailability, args);

  const availabilityState = useSelector((state) =>
    availabilitySelector(
      state,
      args.date,
      procedureCodeId,
      referralSource,
      practitionerId,
      listType,
    ),
  );
  return useStateWithSuspendFlag(availabilityState);
}

export function useCalendarDayAvailability(
  date,
  procedureCodeId,
  referralSource,
  practitionerId,
  listType,
) {
  const availabilityState = useCalendarMonthAvailability(
    date,
    procedureCodeId,
    referralSource,
    practitionerId,
    listType,
  );
  return useMemo(
    () => ({
      ...availabilityState,
      data: availabilityState.data[dateToKey(date, DAY_KEY_FORMAT)] ?? null,
    }),
    [availabilityState, date],
  );
}

function extractKeysFromList(list) {
  const keys = {};
  list;
  return keys;
}
