import { Button } from '../../../lib/components/button';
import { Link } from 'react-router-dom';
import { TextField } from '../../../lib/components/form/text-field';
import { FormProvider, useForm } from 'react-hook-form';
import { LoginPage } from './page';
import { AUTH_PROVIDER_SOOPA } from '../../../lib/constants';
import { useSetAuthenticationDetails } from '../../../lib/api/store/authentication';
import { client } from '../../../lib/api/client';
import invariant from 'tiny-invariant';
import { logger } from '../../../lib/logger';

export function LoginSoopa() {
  const form = useForm();
  //const navigate = useNavigate();
  // const postLoginTarget = usePostLoginTarget();
  const setAuthenticationDetails = useSetAuthenticationDetails();
  const { isSubmitting, isLoading } = form.formState;

  const handleSignIn = async ({ username, password }) => {
    try {
      const api = client();
      const tokens = await api
        .post('authentication/login', { json: { username, password } })
        .json();
      if (tokens) {
        invariant(
          tokens.accessToken?.length > 0 && tokens.refreshToken?.length > 0,
          'Unexpected response from server',
        );
        setAuthenticationDetails({
          provider: AUTH_PROVIDER_SOOPA,
          token: tokens.accessToken,
          soopaAccessToken: tokens.accessToken,
          soopaRefreshToken: tokens.refreshToken,
        });
      }
    } catch (error) {
      logger.warn({ error }, 'Login failed');
      form.reset();
    }
  };

  return (
    <LoginPage>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSignIn)}
          className="w-full flex flex-col items-stretch dark space-y-2"
        >
          <TextField label="Username" field="username" autocomplete="username" />
          <TextField
            label="Password"
            field="password"
            type="password"
            autocomplete="current-password"
          />
          <div>
            <Button
              label="Sign in"
              type="submit"
              size="large"
              disabled={isSubmitting || isLoading}
              className="mt-6 w-full"
              testId="sign-in"
            />
          </div>
          <div className="text-center text-white/50 text-sm">
            <Link className="inline-block mt-4" to={-1} data-testid="go-back">
              Back
            </Link>
          </div>
        </form>
      </FormProvider>
    </LoginPage>
  );
}

export async function soopaSignOut() {}
