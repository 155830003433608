import { FormProvider, useForm } from 'react-hook-form';
import { LIST_SCHEMA } from '../api/schemas';
import { yup } from '../utils';
import { PractitionerAvatar } from './avatar';
import { TextField } from './form/text-field';
import { Table, Td, Th } from './table';
import { useTranslation } from 'react-i18next';
import { useProcedureCategory, useProcedureCategoryGroup } from '../api/store/procedures';
import { Button } from './button';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { EnumRadioGroup } from './enum-radio-group';

export function ListPreferences({ list }) {
  const updateListPreferencesForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      availableSlotCount: list.listRuleset?.availableSlotCount ?? 0,
      targetProcedurePoints: list.listRuleset?.targetProcedurePoints ?? 0,
      maximumProcedurePoints: list.listRuleset?.maximumProcedurePoints ?? 0,
      listType: list.listType,
    },
  });

  return (
    <FormProvider {...updateListPreferencesForm}>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-[1fr_2fr] gap-4">
        <div>
          <Section title="Specialist">
            <div className="flex flex-row items-center space-x-2">
              <PractitionerAvatar practitioner={list.practitioner} className="size-8" />
              <span className="truncate max-w-full text-sm">{list.practitioner.nameText}</span>
            </div>
          </Section>
          <Section title="Available slots">
            <TextField field="availableSlotCount" disabled />
          </Section>
          <Section title="Min points (target)">
            <TextField field="targetProcedurePoints" disabled />
          </Section>
          <Section title="Max points">
            <TextField field="maximumProcedurePoints" disabled />
          </Section>
          <Section title="List type">
            <EnumRadioGroup name="ListType" field="listType" disabled />
          </Section>
        </div>
        <div>
          <Table className="grid-cols-[2fr_2fr_1fr_3fr]">
            <thead className="contents">
              <tr className="contents">
                <Th>Type</Th>
                <Th>Rule</Th>
                <Th>Number</Th>
                <Th>Is</Th>
              </tr>
            </thead>
            <tbody className="contents">
              {list.listRuleset?.procedureCategoryRules?.map((rule) => (
                <ProcedureCategoryRule key={rule.id} rule={rule} />
              ))}
              {list.listRuleset?.procedureCategoryGroupRules?.map((rule) => (
                <ProcedureCategoryGroupRule key={rule.id} rule={rule} />
              ))}
              {list.listRuleset?.referralSourceRules?.map((rule) => (
                <ReferralSourceRule key={rule.id} rule={rule} />
              ))}
            </tbody>
          </Table>
          <Button
            label="Add new rule"
            icon={PlusCircleIcon}
            variant="outline"
            color="gray"
            disabled
          />
        </div>
      </div>
    </FormProvider>
  );
}

ListPreferences.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};

function Section({ title, children }) {
  return (
    <div className="grid grid-cols-[180px_1fr] gap-y-5 items-center border-b border-gray-100 p-5 last:border-b-0">
      <div className="py-2 self-start">
        <label className="text-gray-600 text-sm font-medium">{title}</label>
      </div>
      {children}
    </div>
  );
}

Section.propTypes = {
  title: yup.string().pt(),
  children: yup.mixed().react().required().pt(),
};

const RULE_SHARED_PROPS = {
  id: yup.string().guid(),
  ruleType: yup.string(),
  value: yup.number(),
};

function RuleValue({ rule }) {
  const { t } = useTranslation();
  if (rule.ruleType !== 'OnlyAllow' && rule.ruleType !== 'DoNotAllow') {
    return rule.value;
  } else {
    return <span className="text-gray-300">{t('n/a')}</span>;
  }
}

RuleValue.propTypes = {
  rule: yup.object(RULE_SHARED_PROPS).required().pt(),
};

function RuleType({ rule }) {
  const { t } = useTranslation();
  return t(`enums.RuleType.${rule.ruleType}`);
}

RuleType.propTypes = {
  rule: yup.object(RULE_SHARED_PROPS).required().pt(),
};

function ReferralSourceRule({ rule }) {
  const { t } = useTranslation();
  return (
    <tr className="contents">
      <Td className="font-semibold">Referral source</Td>
      <Td className="uppercase">
        <RuleType rule={rule} />
      </Td>
      <Td>
        <RuleValue rule={rule} />
      </Td>
      <Td>{t(`enums.ReferralSource.${rule.referralSource}`)}</Td>
    </tr>
  );
}

ReferralSourceRule.propTypes = {
  rule: yup
    .object({
      ...RULE_SHARED_PROPS,
      referralSource: yup.string(),
    })
    .required()
    .pt(),
};

function ProcedureCategoryRule({ rule }) {
  const procedureCategory = useProcedureCategory(rule.procedureCategoryId);
  return (
    <tr className="contents">
      <Td>Procedure Category</Td>
      <Td className="uppercase">
        <RuleType rule={rule} />
      </Td>
      <Td>
        <RuleValue rule={rule} />
      </Td>
      <Td>{procedureCategory?.shortDisplay ?? null}</Td>
    </tr>
  );
}

ProcedureCategoryRule.propTypes = {
  rule: yup
    .object({
      ...RULE_SHARED_PROPS,
      procedureCategoryId: yup.string().guid(),
    })
    .required()
    .pt(),
};

function ProcedureCategoryGroupRule({ rule }) {
  const procedureCategoryGroup = useProcedureCategoryGroup(rule.procedureCategoryGroupId);
  return (
    <tr className="contents">
      <Td>Procedure Category</Td>
      <Td className="uppercase">
        <RuleType rule={rule} />
      </Td>
      <Td>
        <RuleValue rule={rule} />
      </Td>
      <Td>{procedureCategoryGroup?.shortDisplay ?? null}</Td>
    </tr>
  );
}

ProcedureCategoryGroupRule.propTypes = {
  rule: yup
    .object({
      ...RULE_SHARED_PROPS,
      procedureCategoryGroupId: yup.string().guid(),
    })
    .required()
    .pt(),
};
