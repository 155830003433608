import { configureStore } from '@reduxjs/toolkit';
import practitionersReducer from './practitioners';
import authenticationReducer from './authentication';
import proceduresReducer from './procedures';
import calendarReducer from './calendar';
import enumsReducer from './enums';
import availabilityReducer from './availability';
import searchReducer from './search';
import healthReducer from './health';

export default configureStore({
  reducer: {
    authentication: authenticationReducer,
    practitioners: practitionersReducer,
    procedures: proceduresReducer,
    calendar: calendarReducer,
    availability: availabilityReducer,
    enums: enumsReducer,
    search: searchReducer,
    health: healthReducer,
  },
});
