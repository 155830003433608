import { useFormContext } from 'react-hook-form';
import { yup } from '../../utils';
import { EMPTY_OBJECT } from '../../constants';
import { ErrorIcon, ErrorMessage } from './error-message';
import { useMount } from 'react-use';
import clsx from 'clsx';

export function TextField({
  field,
  placeholder = '',
  label = '',
  hint = '',
  help = '',
  className,
  disabled = false,
  type = 'text',
  form,
  autocomplete = 'off',
  errorTooltip = false,
  autoFocus = false,
}) {
  const { register, setFocus, formState } = useFormContext();
  const { errors } = formState;
  const error = errors[field]?.message ?? '';
  const inputProps = field ? register(field) : EMPTY_OBJECT;
  const hasLabel = label.length > 0;
  const hasError = error.length > 0;
  useMount(() => {
    if (autoFocus) {
      setFocus(field);
    }
  });
  return (
    <div className={clsx({ hidden: type === 'hidden' }, 'relative')}>
      <div className={clsx('flex justify-between', hasLabel ? 'mb-1' : 'hidden')}>
        <label
          htmlFor={field}
          className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {label}
        </label>
        <span className="text-sm leading-6 text-gray-500">{hint}</span>
      </div>
      <div className="relative rounded-md shadow-sm">
        <input
          {...inputProps}
          type={type}
          form={form}
          id={field}
          className={clsx(
            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6',
            hasError
              ? 'text-error-500 border-error-500 placeholder:text-error-300 ring-error-500 focus:ring-error-500 pr-7'
              : 'text-gray-900 border-gray-300 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-500 focus:border-indigo-500',
            className,
          )}
          placeholder={placeholder}
          disabled={disabled}
          aria-invalid={hasError}
          autoComplete={autocomplete}
        />
        <ErrorIcon error={error} tooltip={errorTooltip} />
      </div>
      <ErrorMessage error={!errorTooltip ? error : ''} help={help} />
    </div>
  );
}

TextField.propTypes = {
  field: yup.string().required().pt(),
  placeholder: yup.string().pt(),
  label: yup.string().pt(),
  hint: yup.string().pt(),
  help: yup.string().pt(),
  error: yup.string().pt(),
  className: yup.string().pt(),
  disabled: yup.boolean().pt(),
  type: yup.string().oneOf(['text', 'password', 'hidden']).pt(),
  form: yup.string().pt(),
  autocomplete: yup.string().pt(),
  errorTooltip: yup.boolean().pt(),
  autoFocus: yup.boolean().pt(),
};
