import clsx from 'clsx';
import { SLOT_SCHEMA } from '../../api/schemas';
import { yup } from '../../utils';
import { DateTime } from '../date-time';

export const COLUMNS = {
  slot: { class: 'w-8 text-center' },
  arrival: { class: 'w-[60px] pl-3' },
  status: { class: 'w-28 pl-3' },
  procedure: { class: 'pl-3' },
  note: { class: 'w-12 pl-3 text-center relative' },
  nhi: { class: 'w-24 pl-3' },
  family: { class: 'w-28 pl-3' },
  given: { class: 'w-32 pl-3' },
  phone: { class: 'w-28 pl-3' },
  email: { class: 'w-48 pl-3' },
  source: { class: 'w-28 pl-3' },
  webpas: { class: 'w-16 pl-3 text-center pr-0px' },
  primary: { class: 'w-24 pl-3' },
  secondary: { class: 'w-14 pl-3' },
};

export const APPOINTMENT_STATUSES = {
  CANCELLED: 'Cancelled',
  ENTERED_IN_ERROR: 'EnteredInError',
};

export const APPOINTMENT_STATUSES_TO_OMIT = [
  'Arrived',
  'Fulfilled',
  'NoShow',
  'CheckIn',
  'Waitlist',
];

function TdHead({ className, children, onClick, onClickHead, ...props }) {
  return (
    <td
      {...props}
      className={clsx(
        'h-16 section1 border-t border-gray-200 transition',
        {
          'cursor-pointer hover:bg-indigo-50 group-has-[.section1:hover]:bg-indigo-50': onClickHead,
          'group-has-[.section2:hover]:bg-indigo-50': onClick,
        },
        className,
      )}
      onClick={onClickHead}
    >
      {children}
    </td>
  );
}

TdHead.propTypes = {
  className: yup.string().pt(),
  onClick: yup.mixed().callback().nullable().pt(),
  onClickHead: yup.mixed().callback().nullable().pt(),
  children: yup.mixed().react().pt(),
};

export function Td({ className, children, onClick, onClickHead, ...props }) {
  onClickHead; // Unused but should be spread into the td element
  return (
    <td
      {...props}
      className={clsx(
        'relative pl-3 h-16 section2 border-t border-gray-200 transition',
        { 'cursor-pointer hover:bg-indigo-50 group-has-[.section2:hover]:bg-indigo-50': onClick },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </td>
  );
}

Td.propTypes = TdHead.propTypes;

export function TdSlotNumber({ index, className, ...props }) {
  return (
    <TdHead {...props} className={clsx('font-light text-center pl-0', className)}>
      {`${index}`}
    </TdHead>
  );
}

TdSlotNumber.propTypes = {
  index: yup.number().pt(),
  className: yup.string().pt(),
};

export function TdArrivalTime({ slot, className, ...props }) {
  return (
    <TdHead {...props} className={clsx('font-medium pl-3', className)}>
      <DateTime t={slot.arrivalTimeTimestamp} format="HH:mm" />
    </TdHead>
  );
}

TdArrivalTime.propTypes = {
  slot: SLOT_SCHEMA.required().pt(),
  className: yup.string().pt(),
};

export function RowSlotNumberAndArrivalTime({ index, slot, ...props }) {
  return (
    <>
      <TdSlotNumber index={index} {...props} />
      <TdArrivalTime slot={slot} {...props} />
    </>
  );
}

RowSlotNumberAndArrivalTime.propTypes = {
  index: yup.number().pt(),
  slot: SLOT_SCHEMA.required().pt(),
};
