import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useApiPost } from '../client';
import { actions as calendarActions } from '../store/calendar';

export function useSaveAppointment() {
  const dispatch = useDispatch();
  const saveAppointment = useApiPost('appointment/save');
  return useMemo(
    () => ({
      ...saveAppointment,
      execute: async ({
        calendarSlotId,
        patientId,
        nhiNumber,
        nameGiven,
        nameFamily,
        email,
        phoneNumber,
        referralSource,
        procedureCodeId,
        appointmentNote,
        appointmentStatus,
      }) => {
        const body = {
          calendarSlotId,
          referralSource,
          procedure: {
            procedureCode: {
              id: procedureCodeId,
            },
          },
          status: appointmentStatus,
          notes: appointmentNote ?? '',
        };
        if (patientId?.length > 0) {
          body.patientId = patientId;
          body.patient = { nameGiven, nameFamily, email, phoneNumber };
        } else if (nhiNumber?.length > 0) {
          body.patient = { nhiNumber, nameGiven, nameFamily, email, phoneNumber };
        }
        const savedAppointment = await saveAppointment.execute(body);
        dispatch(calendarActions.updateAppointment(savedAppointment));
        return savedAppointment;
      },
    }),
    [dispatch, saveAppointment],
  );
}

export function useUpdateAppointment() {
  const dispatch = useDispatch();
  const saveAppointment = useApiPost('appointment/save');
  return useMemo(
    () => ({
      ...saveAppointment,
      execute: async ({
        calendarSlotId,
        appointmentId,
        patientId,
        nhiNumber,
        nameGiven,
        nameFamily,
        email,
        phoneNumber,
        referralSource,
        procedureCodeId,
        appointmentNote,
        appointmentStatus,
        isEnteredInWebPas,
      }) => {
        const body = {
          calendarSlotId,
          appointmentId,
          referralSource,
          procedure: {
            procedureCode: {
              id: procedureCodeId,
            },
          },
          status: appointmentStatus,
          notes: appointmentNote ?? '',
          isEnteredInWebPas,
        };
        if (patientId?.length > 0) {
          body.patientId = patientId;
          body.patient = { nameGiven, nameFamily, email, phoneNumber };
        } else if (nhiNumber?.length > 0) {
          body.patient = { nhiNumber, nameGiven, nameFamily, email, phoneNumber };
        }
        const savedAppointment = await saveAppointment.execute(body);
        dispatch(calendarActions.updateAppointment(savedAppointment));
        return savedAppointment;
      },
    }),
    [dispatch, saveAppointment],
  );
}

export function useSetAppointmentSynced() {
  const dispatch = useDispatch();
  const setWebPasFlag = useApiPost('appointment/setwebpasflag');
  return useMemo(
    () => ({
      ...setWebPasFlag,
      execute: async ({ appointmentId, isEnteredInWebPas = true }) => {
        const body = {
          appointmentId,
          isEnteredInWebPas,
        };
        const updatedCalendarSlot = await setWebPasFlag.execute(body);
        const { calendarListId: listId, ...calendarSlot } = updatedCalendarSlot;
        dispatch(calendarActions.addCalendarSlot({ listId, calendarSlot }));
        return updatedCalendarSlot;
      },
    }),
    [dispatch, setWebPasFlag],
  );
}
