import { decodeJwt } from 'jose';
import { useAsync } from 'react-async-hook';
import toast from 'react-hot-toast';
import { Button } from '../../../lib/components/button';
import SoopaSmile from 'jsx:../../../assets/soopa-smile.svg';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { urlJoin, yup } from '../../../lib/utils';
import MicrosoftIcon from 'jsx:../../../assets/microsoft-icon.svg';
import { LoginMicrosoft, microsoftSignOut } from './microsoft';
import { LoginSoopa } from './soopa';
import { LoginPage } from './page';
import { LoginGoogle } from './google';
import { usePostLoginTarget } from './shared';
import { Loading } from '../loading';
import { DefaultToastContent, errorToast } from '../../../lib/components/toast';
import { AUTH_PROVIDER_GOOGLE, AUTH_PROVIDER_MICROSOFT } from '../../../lib/constants';
import { client } from '../../../lib/api/client';
import {
  useAuthenticationDetails,
  useIsAuthenticated,
  usePreviousAuthProvider,
  useResetAuthenticationDetails,
  useSetAuthenticationDetails,
} from '../../../lib/api/store/authentication';
import { useCallback } from 'react';
import invariant from 'tiny-invariant';
import { useEffectOnce } from 'react-use';

const LOGIN_ERROR_TOAST_ID = 'login-error';
const DELAYED_NOTIFICATION_KEY = 'delayed-notification';
const DELAYED_NOTIFICATIONS = Object.freeze({
  NOT_A_SOOPA_USER: {
    type: 'error',
    args: [
      <DefaultToastContent
        key="not-a-soopa-user"
        title="Not a Soopa account"
        description="You don't have an account with Soopa. If you think this is incorrect please contact your organization's Soopa account manager."
      />,
      { id: LOGIN_ERROR_TOAST_ID, duration: 30000 },
    ],
  },
  SIGN_IN_ERROR: {
    type: 'error',
    args: [
      <DefaultToastContent
        key="sign-in-failed"
        title="Sign in failed"
        description="An unexpected error occurred signing you in. Please try again later."
      />,
      { id: LOGIN_ERROR_TOAST_ID, duration: 30000 },
    ],
  },
});

function useDelayedNotification() {
  useEffectOnce(() => {
    const delayedNotificationType = sessionStorage.getItem(DELAYED_NOTIFICATION_KEY);
    if (delayedNotificationType) {
      sessionStorage.removeItem(DELAYED_NOTIFICATION_KEY);
      const delayedNotification = DELAYED_NOTIFICATIONS[delayedNotificationType];
      if (delayedNotification) {
        switch (delayedNotification.type) {
          case 'error':
            errorToast(...delayedNotification.args);
            break;
        }
      }
    }
  }, []);
}

function Greeter() {
  const [params] = useSearchParams();
  const removeErrorToast = useCallback(() => toast.dismiss(LOGIN_ERROR_TOAST_ID), []);

  useDelayedNotification();

  // If we successfully logged in before we automatically try that provider
  // again.
  const [previousAuthProvider] = usePreviousAuthProvider();
  switch (previousAuthProvider) {
    case 'microsoft':
      return <Navigate to={urlJoin('microsoft', params)} />;
    default:
      return (
        <LoginPage>
          <div className="w-full flex flex-col items-stretch space-y-6">
            <Button
              label="Sign in with Microsoft"
              icon={MicrosoftIcon}
              size="large"
              onClick={removeErrorToast}
              to={urlJoin('microsoft', params)}
              testId="login-microsoft"
            />
            <Button
              label="Sign in with Soopa"
              icon={SoopaSmile}
              size="large"
              onClick={removeErrorToast}
              to={urlJoin('soopa', params)}
              testId="login-soopa"
            />
          </div>
        </LoginPage>
      );
  }
}

export const loginRoute = {
  path: 'authentication',
  children: [
    {
      index: true,
      element: (
        <RedirectAfterLogin>
          <Greeter />
        </RedirectAfterLogin>
      ),
    },
    {
      path: 'soopa',
      element: (
        <RedirectAfterLogin>
          <LoginSoopa />
        </RedirectAfterLogin>
      ),
    },
    {
      path: 'microsoft',
      element: (
        <RedirectAfterLogin>
          <ExchangeSsoTokenForSoopaToken>
            <LoginMicrosoft />
          </ExchangeSsoTokenForSoopaToken>
        </RedirectAfterLogin>
      ),
    },
    {
      path: 'google',
      element: (
        <RedirectAfterLogin>
          <ExchangeSsoTokenForSoopaToken>
            <LoginGoogle />
          </ExchangeSsoTokenForSoopaToken>
        </RedirectAfterLogin>
      ),
    },
  ],
};

function RedirectAfterLogin({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const location = usePostLoginTarget();
  return !isAuthenticated ? children : <Navigate to={location} replace={true} />;
}

RedirectAfterLogin.propTypes = {
  children: yup.mixed().react().pt(),
};

async function exchangeSsoTokenForSoopaTokens(jwt) {
  const api = client();
  const tokens = await api
    .post('authentication/loginjwt', { json: { provider: getJwtProvider(jwt), jwt } })
    .json();
  invariant(
    tokens.accessToken?.length > 0 && tokens.refreshToken?.length > 0,
    'Unexpected response from server',
  );
  return { soopaAccessToken: tokens.accessToken, soopaRefreshToken: tokens.refreshToken };
}

function getJwtProvider(jwt) {
  const claims = decodeJwt(jwt);
  if (claims?.iss?.includes('microsoft')) {
    return 'Microsoft';
  }
  return 'unknown';
}

function ExchangeSsoTokenForSoopaToken({ children }) {
  const navigate = useNavigate();
  const details = useAuthenticationDetails();
  const { provider, token } = details;
  const setAuthenticationDetails = useSetAuthenticationDetails();
  const resetAuthenticationDetails = useResetAuthenticationDetails();

  const { loading } = useAsync(async () => {
    if (token?.length > 0) {
      try {
        const tokens = await exchangeSsoTokenForSoopaTokens(token);
        if (tokens) {
          setAuthenticationDetails({ provider, token, ...tokens });
        }
      } catch (error) {
        const isUnknownUser = error.response?.status === 401;
        sessionStorage.setItem(
          DELAYED_NOTIFICATION_KEY,
          isUnknownUser ? 'NOT_A_SOOPA_USER' : 'SIGN_IN_ERROR',
        );
        resetAuthenticationDetails();
        switch (provider) {
          case AUTH_PROVIDER_MICROSOFT: {
            await microsoftSignOut();
            break;
          }
          case AUTH_PROVIDER_GOOGLE:
          default: {
            break;
          }
        }
        // Sometimes the provider logout will redirect but if it does not we
        // redirect to the login page here.
        navigate('/authentication', { replace: true });
      }
    }
  }, [token]);

  return loading ? <Loading /> : children;
}

ExchangeSsoTokenForSoopaToken.propTypes = {
  children: yup.mixed().react().pt(),
};
