import { useMemo } from 'react';
import { yup } from '../utils';
import { useProcedureCodesState } from '../api/store/procedures';
import { ComboBox, LoadingComboBox } from './form/combo-box';

const CLASSES = {
  comboBoxOptions: 'lg:min-w-[30rem]',
};

export function ProcedureComboBox({ field, ...props }) {
  const state = useProcedureCodesState();
  const options = useMemo(() => makeProcedureComboBoxOptions(state.data), [state.data]);
  return state.suspend ? (
    <LoadingComboBox field={field} />
  ) : (
    <ComboBox
      {...props}
      field={field}
      options={options}
      placeholder="Search procedure"
      classes={CLASSES}
    />
  );
}

ProcedureComboBox.propTypes = {
  field: yup.string().required().pt(),
};

function makeProcedureComboBoxOptions(procedures) {
  return Object.values(procedures).map((procedure) => {
    const dataSearch = `${procedure.definition}||${procedure.shortDisplay}||${procedure.procedureCategory.shortDisplay}||${procedure.procedureCategory.procedureCategoryGroup.shortDisplay}`;
    return (
      <ComboBox.LabelItem
        key={procedure.id}
        label={procedure.longDisplay}
        data-search={dataSearch}
      />
    );
  });
}
