import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { yup } from '../../utils';
import { Tooltip } from '../tooltip';
import clsx from 'clsx';

export function ErrorMessage({ error = '', help = '' }) {
  const hasError = error.length > 0;
  const hasHelp = help.length > 0;

  return (
    <p
      className={clsx(
        'text-xs',
        hasError ? 'text-error-500 mt-2' : hasHelp ? 'text-gray-500 mt-2' : 'hidden',
      )}
    >
      {hasError ? error : help}
    </p>
  );
}

ErrorMessage.propTypes = {
  error: yup.string().pt(),
  help: yup.string().pt(),
};

export function ErrorIcon({ error, tooltip }) {
  const hasError = error.length > 0;
  return (
    <div
      className={clsx('absolute inset-y-0 right-0 flex items-center pr-1.5', { hidden: !hasError })}
      data-testid="error-icon"
    >
      {tooltip ? (
        <Tooltip content={error} mode="error">
          <ExclamationCircleIcon className="h-5 w-5 text-error-500" aria-hidden="true" />
        </Tooltip>
      ) : (
        <ExclamationCircleIcon className="h-5 w-5 text-error-500" aria-hidden="true" />
      )}
    </div>
  );
}

ErrorIcon.propTypes = {
  error: yup.string().pt(),
  tooltip: yup.boolean().pt(),
};
