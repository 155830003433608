/* global process */
export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export function NOOP() {}
export function IDENTITY(value) {
  return value;
}
export const API_URL = process.env.API_URL ?? '/api';
export const DEBUG = process.env.NODE_ENV !== 'production';
export const MSAL_CLIENT_ID = process.env.MSAL_CLIENT_ID;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const APPLICATION_INSIGHTS_KEY = process.env.APPLICATION_INSIGHTS_KEY;
export const AUTH_PROVIDER_SOOPA = 'soopa';
export const AUTH_PROVIDER_MICROSOFT = 'microsoft';
export const AUTH_PROVIDER_GOOGLE = 'google';
export const TIMEZONE = 'Pacific/Auckland';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const NHI_REGEX = /^[a-zA-Z]{3}\d{2}(?:\d{2}|[a-zA-Z][a-zA-Z\d])$/;
export const NHI_REGEX_OPTIONAL = /^([a-zA-Z]{3}\d{2}(?:\d{2}|[a-zA-Z][a-zA-Z\d]))?$/;
export const IS_PRODUCTION = process?.env?.NODE_ENV === 'production';
export const GUID_REGEX = /^[\da-fA-F]{8}-([\da-fA-F]{4}-){3}[\da-fA-F]{12}$/;
