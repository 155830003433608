import { useMemo } from 'react';
import { TIMEZONE } from '../constants';
import { yup, dayjs } from '../utils';

export function DateTime({
  t,
  format = 'dddd, MMM D',
  className = 'whitespace-nowrap',
  timezone = TIMEZONE,
}) {
  const text = useMemo(() => dayjs(t).tz(timezone).format(format), [format, t, timezone]);
  return <time className={className}>{text}</time>;
}

DateTime.propTypes = {
  t: yup.mixed().dayjs(false).required().pt(),
  format: yup.string().pt(),
  className: yup.string().pt(),
  timezone: yup.string().pt(),
};
