export function useAppointmentStatusColour(value) {
  switch (value.toLowerCase()) {
    case 'booked':
      return 'shaded-green';
    case 'proposed':
      return 'shaded-pink';
    case 'pending':
      return 'shaded-orange';
    case 'unavailable':
      return 'shaded-black';
    default:
      return 'shaded-gray';
  }
}
