import { useMemo } from 'react';
import { MsalProvider, useMsalAuthentication } from '@azure/msal-react';
import {
  InteractionType,
  NavigationClient,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useAsync } from 'react-async-hook';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useLatest } from 'react-use';
import { Loading } from '../loading';
import { urlJoin, yup } from '../../../lib/utils';
import { usePostLoginTarget } from './shared';
import { AUTH_PROVIDER_MICROSOFT, MSAL_CLIENT_ID } from '../../../lib/constants';
import { logger } from '../../../lib/logger';
import {
  usePreviousAuthProvider,
  useSetAuthenticationDetails,
} from '../../../lib/api/store/authentication';

const PUBLIC_CLIENT_APPLICATION_CONFIG = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    redirectUri: '/authentication/microsoft',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

async function initializePublicClientApplication(callbacksRef) {
  const msalInstance = new PublicClientApplication(PUBLIC_CLIENT_APPLICATION_CONFIG);

  await msalInstance.initialize();

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // TODO: this should probably be changed
    // Default to using the first account if no account is active on page load
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    logger.debug(`Microsoft authentication ${event.eventType} event`);
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
    ) {
      const account = event.payload?.account;
      if (event.eventType === EventType.LOGIN_SUCCESS && account) {
        msalInstance.setActiveAccount(account);
      }
      if (typeof callbacksRef?.current?.onTokensAcquired === 'function') {
        callbacksRef.current.onTokensAcquired(event.payload);
      }
    } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
      if (typeof callbacksRef?.current?.onTokensReset === 'function') {
        callbacksRef.current.onTokensReset();
      }
    }
  });

  return msalInstance;
}

function useInitializedMsalClient() {
  const setAuthenticationDetails = useSetAuthenticationDetails();
  const latestCallbacks = useLatest({
    onTokensAcquired: (tokens) => {
      const { idToken: token } = tokens;
      setAuthenticationDetails({ token, provider: AUTH_PROVIDER_MICROSOFT });
    },
    onTokensReset: () => {
      window.location.href = '/';
    },
  });
  const { result } = useAsync(initializePublicClientApplication, [latestCallbacks]);
  return result;
}

export function LoginMicrosoft() {
  // We reset the previous auth provider here in case we were redirected automatically
  // because we need to give the user the option to log in with another provider
  // if they want.
  const [, resetPreviousAuthProvider] = usePreviousAuthProvider();
  useEffectOnce(resetPreviousAuthProvider);
  return (
    <WithInitializedMsalClient fallback={<Loading />}>
      <LoginMicrosoftInternal />
    </WithInitializedMsalClient>
  );
}

export function LoginMicrosoftInternal() {
  const postLoginTarget = usePostLoginTarget();
  const redirectRequest = useMemo(
    () => ({
      scopes: ['email'],
      redirectStartPage: postLoginTarget,
    }),
    [postLoginTarget],
  );
  useMsalAuthentication(InteractionType.Redirect, redirectRequest);

  return <Loading />;
}

export async function microsoftSignOut(localOnly = false) {
  const instance = await initializePublicClientApplication();
  const account = instance.getActiveAccount();
  const options = { account };
  if (localOnly) {
    options.onRedirectNavigate = () => {
      // Return false to stop redirect after local logout
      return false;
    };
  }
  await instance.logoutRedirect(options);
}

class CustomNavigationClient extends NavigationClient {
  constructor(navigate) {
    super();
    this.navigate = navigate;
  }

  async navigateInternal(url, options) {
    const redirect = url.replace(window.location.origin, '');
    const target = urlJoin('/authentication/microsoft', { redirect });
    if (options.noHistory) {
      this.navigate(target, { replace: true });
    } else {
      this.navigate(target);
    }
    return false;
  }
}

function WithInitializedMsalClient({ children, fallback = null }) {
  const msalInstance = useInitializedMsalClient();
  const navigate = useNavigate();

  if (msalInstance) {
    msalInstance.setNavigationClient(new CustomNavigationClient(navigate));
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  } else {
    return fallback;
  }
}

WithInitializedMsalClient.propTypes = {
  children: yup.mixed().react().pt(),
  fallback: yup.mixed().react().pt(),
};
