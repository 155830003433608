import { createCache } from 'async-cache-dedupe';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'tiny-invariant';
import hash from 'object-hash';
import stringify from 'safe-stable-stringify';
import { client, tryRefreshToken } from '../client';
import { EMPTY_OBJECT, TIMEZONE } from '../../constants';
import { dayjs } from '../../utils';
import { useEffect, useMemo } from 'react';
import { setAuthentication, tokensSelector } from './authentication';
import { logger } from '../../logger';
import { useSharedThunkDispatchManager } from './shared-thunk-dispatch-provider';

export const THUNK_STATUS = Object.freeze({
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
});

export const INITIAL_THUNK_STATE = Object.freeze({
  data: EMPTY_OBJECT,
  status: THUNK_STATUS.IDLE,
  error: undefined,
  loaded: false,
  t: 0,
});

export const KEY = Symbol.for('key');

export function keyedSlicer(state, arg) {
  const key = arg[KEY];
  if (!key) {
    console.dir(key, arg);
  }
  invariant(key, 'Missing key symbol in thunk arg');
  if (!state[key]) {
    state[key] = { ...INITIAL_THUNK_STATE, arg: key };
  }
  return state[key];
}

export const SLICE_REPLACE = (current, next) => next;
export const SLICE_MERGE = (current, next) => ({ ...current, ...next });

export function setThunkStatePending(slice) {
  slice.status = 'loading';
  slice.error = undefined;
  slice.t = Date.now();
}

export function setThunkStateFulfilled(slice, data) {
  slice.status = 'succeeded';
  slice.data = data;
  slice.error = undefined;
  slice.loaded = true;
  slice.t = Date.now();
}

export function setThunkStateRejected(slice, error) {
  slice.status = 'failed';
  slice.error = error;
  slice.t = Date.now();
}

const cache = createCache({ storage: { type: 'memory' } });

cache.define('apiGet', async ({ path, token }) => {
  return await client(token).get(path).json();
});

cache.define('apiPost', async ({ path, token, body }) => {
  return await client(token).post(path, { json: body }).json();
});

export function createAsyncApiThunk(typePrefix, payloadCreator, options) {
  return createAsyncThunk(
    typePrefix,
    async (arg, context) => {
      const state = context.getState();
      const setAuthenticationDetails = (details) => context.dispatch(setAuthentication(details));
      const tokens = tokensSelector(state);
      const token = await tryRefreshToken(
        tokens?.soopa?.access?.encoded,
        tokens?.soopa?.refresh,
        setAuthenticationDetails,
      );
      const apiGet = (path) => cache.apiGet({ path, token });
      const apiPost = (path, body) => cache.apiPost({ path, token, body });
      try {
        return await payloadCreator(arg, { ...context, apiGet, apiPost });
      } catch (error) {
        logger.error({ error }, 'Failure async API thunk');
        throw error;
      }
    },
    options,
  );
}

export function transformArrayToMapping(items, key = (item) => item.id) {
  return items.reduce((acc, val) => ({ ...acc, [key(val)]: val }), {});
}

export function thunkStateIsLoading(state) {
  return state?.status === 'loading';
}

export function useSharedDispatch(
  actionCreator,
  actionArgs = null,
  // TODO: options to set the dispatch interval per
  // eslint-disable-next-line no-unused-vars
  options = EMPTY_OBJECT,
) {
  const { subscribe } = useSharedThunkDispatchManager();
  useEffect(() => {
    if (actionCreator) {
      const unsubscribe = subscribe(actionCreator, actionArgs, options);
      return unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCreator, actionArgs, subscribe, stringify(options)]);
}

export function useStateWithSuspendFlag(state) {
  return useMemo(
    () => ({
      ...state,
      suspend: (state.status === 'idle' || state.status === 'loading') && !state.loaded,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringify(state)],
  );
}

export function assignHashAndKeys(obj, keyExtractor = () => EMPTY_OBJECT) {
  delete obj.$hash;
  delete obj.$keys;
  obj.$hash = hash(obj);
  obj.$keys = keyExtractor(obj);
  return obj;
}

export function makeSafeReducer(func) {
  return (state, action) => {
    try {
      return func(state, action);
    } catch (error) {
      logger.error({ error }, 'Bug in reducer');
    }
  };
}

export function dateToKey(date, format) {
  invariant(dayjs.isDayjs(date), 'Expected a dayjs instance');
  return date.tz(TIMEZONE).format(format);
}
