import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { dayjs } from '../../utils';
import {
  INITIAL_THUNK_STATE,
  createAsyncApiThunk,
  setThunkStatePending,
  setThunkStateFulfilled,
  setThunkStateRejected,
  useSharedDispatch,
} from './utils';
import { useMemo, useState } from 'react';

export const fetchHealth = createAsyncApiThunk('health/fetch', async (_, { apiGet }) => {
  const result = await apiGet('healthcheck', {});
  return result;
});

const healthSlice = createSlice({
  name: 'health',
  initialState: { ...INITIAL_THUNK_STATE },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHealth.pending, (state) => {
        setThunkStatePending(state);
      })
      .addCase(fetchHealth.fulfilled, (state, action) => {
        setThunkStateFulfilled(state, action.payload);
      })
      .addCase(fetchHealth.rejected, (state, action) => {
        setThunkStateRejected(state, action.error);
      });
  },
});

export default healthSlice.reducer;

export function useVersion() {
  const [currentVersion] = useState(() =>
    document.querySelector('meta[name="soopa-version"]')?.getAttribute('content'),
  );
  useSharedDispatch(fetchHealth, null, { pollInterval: 900 * 1000 });
  const state = useSelector(healthSlice.selectSlice);
  return useMemo(
    () => ({
      current: currentVersion,
      currentNumber: parseRawVersion(currentVersion),
      next: state.data?.version,
      nextNumber: parseRawVersion(state.data.version),
      environment: state.data?.env?.toLowerCase() ?? 'unknown',
    }),
    [currentVersion, state.data?.env, state.data.version],
  );
}

export function useEnvironmentClassName() {
  const { environment } = useVersion();
  return `environment-${environment}`;
}

function parseRawVersion(version) {
  const parsed = dayjs(version, 'YYYY.MM.DD.SSS');
  return parsed.isValid() ? parsed.valueOf() : 0;
}
