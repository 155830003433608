import { yup } from '../../../lib/utils';
import SoopaLogo from 'jsx:../../../assets/soopa-logo.svg';
import { BrandPage } from '../brand-page';
import { Version } from '../../../lib/components/version';
import { useUpdateChecker } from '../../../lib/hooks/use-update-checker';

export function LoginPage({ children }) {
  useUpdateChecker();
  return (
    <BrandPage>
      <div className="flex flex-col w-72 items-stretch">
        <div className="mb-6 mx-auto">
          <SoopaLogo className="w-40 h-auto" />
        </div>
        {children}
      </div>
      <div className="text-xs text-white opacity-25 absolute bottom-0 mb-2">
        <Version />
      </div>
    </BrandPage>
  );
}

LoginPage.propTypes = {
  children: yup.mixed().react().pt(),
};
