import { CheckBox } from '../form/check-box';
import { useSetAppointmentSynced } from '../../api/actions/save-appointment';
import invariant from 'tiny-invariant';
import { SLOT_SCHEMA } from '../../api/schemas';
import { useCallback, useState } from 'react';
import { useGenericErrorsToast } from '../toast';

export function UpdateWebPasSyncCheckbox({ slot }) {
  const [override, setOverride] = useState(undefined);
  const appointment = slot.appointment;
  invariant(appointment);
  const genericErrorsToast = useGenericErrorsToast();

  const setAppointmentSynced = useSetAppointmentSynced();

  const handleChange = useCallback(
    async (event) => {
      const isEnteredInWebPas = !!event.target.checked;
      setOverride(isEnteredInWebPas);
      try {
        await setAppointmentSynced.execute({
          appointmentId: appointment.id,
          isEnteredInWebPas,
        });
      } catch (error) {
        genericErrorsToast('Failed to update WebPAS sync state', error);
      } finally {
        setOverride(undefined);
      }
    },
    [appointment.id, genericErrorsToast, setAppointmentSynced],
  );

  return (
    <CheckBox.Uncontrolled
      checked={override === undefined ? appointment.isEnteredInWebPas : override}
      onChange={handleChange}
      disabled={setAppointmentSynced.loading}
      className="inline-block"
    />
  );
}

UpdateWebPasSyncCheckbox.propTypes = {
  slot: SLOT_SCHEMA.pt(),
};
