import { useMemo } from 'react';
import { yup } from '../utils';
import { ScoreBadge } from './score-badge';

export function ListScoreBadge({ list }) {
  const score = useMemo(
    () => ({ status: list.pointStatus, value: list.totalProcedurePoints }),
    [list],
  );
  return <ScoreBadge score={score} />;
}

ListScoreBadge.propTypes = {
  list: yup
    .object({
      pointStatus: yup.string().required(),
      totalProcedurePoints: yup.number().required(),
    })
    .pt(),
};
