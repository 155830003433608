import { useMemo } from 'react';
import { TIMEZONE } from '../constants';
import { useNow } from './use-now';

export function useToday(timezone = TIMEZONE) {
  const now = useNow();
  const today = now.tz(timezone).startOf('day');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => today, [today.toISOString()]);
}
