import { useState } from 'react';
import { useInterval } from 'react-use';
import { dayjs } from '../utils';

/**
 * Wrapper around useInterval that return the current time (as a dayjs
 * instance) every given milliseconds.
 */
export function useNow({ offset = 0, delay = 60000 } = {}) {
  const [now, setNow] = useState(() => dayjs.utc().add(offset, 'ms'));
  useInterval(() => setNow(dayjs.utc().add(offset, 'ms')), delay);
  return now;
}
