import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { dayjs } from '../../lib/utils';
import { TIMEZONE } from '../constants';

export function useDayjs(dateTimeLike, timezone = TIMEZONE) {
  return useMemo(() => {
    let t;
    if (dayjs.isDayjs(dateTimeLike)) {
      t = dateTimeLike;
    } else if (Number.isInteger(dateTimeLike)) {
      t = dateTimeLike < 4294967295 ? dayjs.unix(dateTimeLike) : dayjs(dateTimeLike);
    } else if (dateTimeLike === undefined || dateTimeLike === null) {
      t = dayjs();
    } else if (typeof dateTimeLike === 'string') {
      t = dayjs(dateTimeLike);
    } else {
      invariant(false, 'Unsupported input value');
    }
    return t.tz(timezone);
  }, [dateTimeLike, timezone]);
}
