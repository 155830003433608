/*eslint import/no-unresolved: [2, { ignore: ['^jsx:.+\\.svg$'] }]*/
import { LoadingSpinner } from '../../lib/components/loading-spinner';
import { BrandPage } from './brand-page';

export function Loading() {
  return (
    <BrandPage>
      <div className="text-white/50">
        <LoadingSpinner />
      </div>
    </BrandPage>
  );
}
