export function useStatusColours(status) {
  switch (status) {
    case 'red':
      return 'shaded-red';
    case 'orange':
      return 'shaded-orange';
    case 'green':
      return 'shaded-green';
    case 'black':
      return 'shaded-black';
    default:
      return 'shaded-gray';
  }
}
