import { useMemo } from 'react';
import { useLatest } from 'react-use';
import { yup } from '../utils';
import { LoadingRadioGroup, RadioGroup } from './form/radio-group';
import { useEnumState } from '../api/store/enums';

export function EnumRadioGroup({
  name,
  field,
  makeEnumRadioGroupOptions = defaultMakeEnumRadioGroupOptions,
  ...props
}) {
  const state = useEnumState(name);
  const latestMakeOptions = useLatest(makeEnumRadioGroupOptions);
  const options = useMemo(
    () => latestMakeOptions.current(state.data ?? []),
    [latestMakeOptions, state.data],
  );
  if (state.suspend) {
    return <LoadingRadioGroup {...props} field={field} />;
  } else {
    return <RadioGroup {...props} field={field} options={options} />;
  }
}

EnumRadioGroup.propTypes = {
  name: yup.string().required().pt(),
  field: yup.string().required().pt(),
  makeEnumRadioGroupOptions: yup.mixed().callback().pt(),
};

export function defaultMakeEnumRadioGroupOptions(enumValues) {
  return Object.entries(enumValues).map(([k, v]) => <RadioGroup.LabelItem key={k} label={v} />);
}
