import { XMarkIcon } from '@heroicons/react/24/outline';
import { yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import {
  Td,
  TdArrivalTime,
  TdSlotNumber,
  APPOINTMENT_STATUSES,
  APPOINTMENT_STATUSES_TO_OMIT,
} from './shared';
import { useCallback } from 'react';
import { EnumListBox } from '../enum-list-box';
import { Button } from '../button';
import { useCancelAppointmentState } from '../../api/actions/cancel-appointment';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckBox } from '../form/check-box';
import { useToggle } from 'react-use';
import { useGenericErrorsToast } from '../toast';
import {
  CancellationReasonFormFields,
  determineDefaultCancellationTime,
} from '../cancellation-reason-form-fields';

function FilledSlotRowRemove({
  index,
  slot,
  setMode,
  setInitialAppointmentStatus,
  cancelAppointmentStatus,
  ContentComponent,
}) {
  const onStatusChange = useCallback(
    (appointmentStatus) => {
      if (appointmentStatus === APPOINTMENT_STATUSES.ENTERED_IN_ERROR) {
        setMode(2);
      } else if (appointmentStatus === APPOINTMENT_STATUSES.CANCELLED) {
        setMode(4);
      } else {
        setInitialAppointmentStatus(appointmentStatus);
        setMode(1);
      }
    },
    [setInitialAppointmentStatus, setMode],
  );
  const genericErrorsToast = useGenericErrorsToast();

  const cancelAppointment = useCancelAppointmentState();
  const onRemove = useCallback(
    async (values) => {
      try {
        await cancelAppointment.execute({
          appointmentId: slot.appointment.id,
          cancellationStatus: cancelAppointmentStatus,
          cancellationTime: values.cancellationTime ?? 'None',
          cancellationReason: values.cancellationReason ?? 'None',
          cancellationNotes: values.cancellationNotes ?? '',
        });
        setMode(0);
      } catch (error) {
        genericErrorsToast('Failed to cancel/delete appointment', error);
      }
    },
    [cancelAppointment, cancelAppointmentStatus, genericErrorsToast, setMode, slot.appointment.id],
  );

  return (
    <tr className="border-t border-gray-200 bg-red-50">
      <TdSlotNumber index={index} />
      <TdArrivalTime slot={slot} />
      <Td>
        <EnumListBox
          name="AppointmentStatus"
          placeholder="Status"
          onChange={onStatusChange}
          value={cancelAppointmentStatus}
          valuesToOmit={APPOINTMENT_STATUSES_TO_OMIT}
        />
      </Td>
      <Td colSpan={9}>
        <ContentComponent disabled={cancelAppointment.loading} onRemove={onRemove} />
      </Td>
      <Td className="flex justify-end items-center pr-3">
        <Button
          icon={XMarkIcon}
          variant="plain"
          labelClassName="sr-only"
          onClick={() => setMode(0)}
        />
      </Td>
    </tr>
  );
}

FilledSlotRowRemove.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
  setMode: yup.mixed().callback().pt(),
  setInitialAppointmentStatus: yup.mixed().callback().pt(),
  cancelAppointmentStatus: yup
    .string()
    .oneOf([APPOINTMENT_STATUSES.ENTERED_IN_ERROR, APPOINTMENT_STATUSES.CANCELLED])
    .required()
    .pt(),
  ContentComponent: yup.mixed().react().required().pt(),
};

export function FilledSlotRowDelete(props) {
  return (
    <FilledSlotRowRemove
      {...props}
      cancelAppointmentStatus={APPOINTMENT_STATUSES.ENTERED_IN_ERROR}
      ContentComponent={EnteredInErrorRowContent}
    />
  );
}

function EnteredInErrorRowContent({ disabled, onRemove }) {
  const [checked, toggleChecked] = useToggle(false);
  return (
    <div className="flex gap-x-3.5 items-center space-x-4">
      <div className="content-center italic">
        This status change is permanent, and will delete this appointment from this list. Are you
        sure?
      </div>
      <CheckBox.Uncontrolled
        label="Removed from WebPAS"
        onClick={toggleChecked}
      ></CheckBox.Uncontrolled>
      <Button
        label="YES, DELETE APPOINTMENT"
        variant="plain"
        color="red"
        type="button"
        className="min-w-16"
        disabled={disabled || !checked}
        onClick={() => onRemove({})}
      />
    </div>
  );
}

EnteredInErrorRowContent.propTypes = {
  disabled: yup.boolean().required().pt(),
  onRemove: yup.mixed().callback().pt(),
};

const CANCEL_APPOINTMENT_FORM_SCHEMA = yup.object().shape({
  appointmentStatus: yup.string().required(),
  cancellationTime: yup.string().required(),
  cancellationReason: yup.string().required(),
  cancellationNotes: yup.string(),
  webpasChecked: yup.boolean().oneOf([true]).required(),
});

export function FilledSlotRowCancel(props) {
  const cancelAppointmentForm = useForm({
    resolver: yupResolver(CANCEL_APPOINTMENT_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: {
      appointmentStatus: APPOINTMENT_STATUSES.CANCELLED,
      cancellationTime: determineDefaultCancellationTime(props.slot?.arrivalTimeTimestamp),
      cancellationReason: '',
      cancellationNotes: '',
      webpasChecked: false,
    },
  });

  return (
    <FormProvider {...cancelAppointmentForm}>
      <FilledSlotRowRemove
        {...props}
        cancelAppointmentStatus={APPOINTMENT_STATUSES.CANCELLED}
        ContentComponent={CancelledRowContent}
      />
    </FormProvider>
  );
}

FilledSlotRowCancel.propTypes = {
  slot: yup.object({ arrivalTimeTimestamp: yup.number() }).required().pt(),
};

function CancelledRowContent({ disabled, onRemove }) {
  const { handleSubmit, formState } = useFormContext();
  const { isSubmitting, isValid, isLoading } = formState;
  const formDisabled = disabled || !isValid || isSubmitting || isLoading;

  const onSubmit = useCallback(
    async (values) => {
      await onRemove({
        cancellationTime: values.cancellationTime,
        cancellationReason: values.cancellationReason,
        cancellationNotes: values.cancellationNotes,
      });
    },
    [onRemove],
  );

  return (
    <div className="flex gap-x-4 items-center">
      <CancellationReasonFormFields />
      <CheckBox
        field="webpasChecked"
        label="Removed from WebPAS"
        disabled={isSubmitting || isLoading}
      ></CheckBox>
      <Button
        label="CANCEL APPOINTMENT"
        variant="plain"
        color="red"
        type="button"
        className="min-w-16"
        disabled={formDisabled}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </div>
  );
}

CancelledRowContent.propTypes = EnteredInErrorRowContent.propTypes;
