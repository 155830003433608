import { yup } from '../../utils';
import { useAppointmentStatusColour } from '../../hooks/use-appointment-status-colour';
import { Label } from '../label';

export function AppointmentStatusLabel({ status }) {
  const className = useAppointmentStatusColour(status);
  return <Label label={status} className={className} />;
}

AppointmentStatusLabel.propTypes = {
  status: yup.string().required().pt(),
};
