import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createContext, useCallback, useContext, useState } from 'react';
import { useAuthenticatedIdentity } from '../api/store/authentication';
import { useChange } from '../hooks/use-change';
import { yup } from '../utils';
import { APPLICATION_INSIGHTS_KEY, IS_PRODUCTION, API_URL } from '../constants';

function loadApplicationInsights() {
  if (APPLICATION_INSIGHTS_KEY) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APPLICATION_INSIGHTS_KEY,
        endpointUrl: `${API_URL}/telemetry`,
        disableExceptionTracking: false,
        disableTelemetry: false,
        disableAjaxTracking: false,
        disableFetchTracking: false,
        disableXhr: false,
        cookieCfg: {
          enabled: false,
        },
        enableAutoRouteTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          AppInsightsCfgSyncPlugin: {
            cfgUrl: '', // this will block fetching from default cdn
          },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
      envelope.data['soopa.production'] = IS_PRODUCTION;
    });
    return appInsights;
  }
}

const ApplicationInsightsContext = createContext();

export function ApplicationInsightsProvider({ children }) {
  const [applicationInsights] = useState(loadApplicationInsights);
  const auth = useAuthenticatedIdentity();
  useChange(auth, () => {
    if (applicationInsights) {
      const userId = auth['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
      if (userId) {
        applicationInsights.setAuthenticatedUserContext(userId, null, false);
      } else {
        applicationInsights.clearAuthenticatedUserContext();
      }
    }
  });
  return (
    <ApplicationInsightsContext.Provider value={applicationInsights}>
      {children}
    </ApplicationInsightsContext.Provider>
  );
}

ApplicationInsightsProvider.propTypes = {
  children: yup.mixed().react().pt(),
};

export function useTrackException() {
  const applicationInsights = useContext(ApplicationInsightsContext);
  return useCallback(
    (exception, properties) => {
      if (applicationInsights) {
        applicationInsights.trackException(exception, properties);
      }
    },
    [applicationInsights],
  );
}
