import clsx from 'clsx';
import { yup } from '../utils';

export function Table({ className, children }) {
  return (
    <div className="rounded-lg border border-gray-200 relative mb-4">
      <table
        className={clsx(
          'grid w-full text-gray-700 text-left font-normal text-sm items-center',
          className,
        )}
      >
        {children}
      </table>
    </div>
  );
}

Table.propTypes = {
  className: yup.string().pt(),
  children: yup.mixed().react().pt(),
};

export function Th({ className, children }) {
  return (
    <th
      className={clsx(
        className,
        'pl-3 py-4 bg-gray-50 uppercase tracking-wide text-left font-bold text-xs whitespace-nowrap overflow-hidden text-ellipsis',
      )}
    >
      {children}
    </th>
  );
}

Th.propTypes = {
  className: yup.string().pt(),
  children: yup.mixed().react().pt(),
};

export function Td({ className, children }) {
  return (
    <td
      className={clsx(
        className,
        'pl-3 border-t border-gray-200 group-hover:bg-indigo-50 h-16 min-w-0 flex items-center',
      )}
    >
      <div className="block whitespace-nowrap overflow-hidden text-ellipsis w-full">{children}</div>
    </td>
  );
}

Td.propTypes = {
  className: yup.string().pt(),
  children: yup.mixed().react().pt(),
};
