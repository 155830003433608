import clsx from 'clsx';
import { useEnvironmentClassName } from '../../lib/api/store/health';
import { yup } from '../../lib/utils';

export function BrandPage({ children }) {
  const environmentClass = useEnvironmentClassName();
  return (
    <div
      className={clsx(
        environmentClass,
        'h-full w-full bg-brand text-white flex flex-col justify-center items-center',
      )}
    >
      {children}
    </div>
  );
}

BrandPage.propTypes = {
  children: yup.mixed().react().pt(),
};
