import { cloneElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { yup } from '../utils';

export function IconButton({ to, label, icon, size = 'normal', onClick }) {
  const { buttonStyle, iconStyle } = useMemo(() => {
    let buttonStyles = ['relative rounded-md text-gray-800 focus:outline-none'];
    let iconStyles = [];

    switch (size) {
      default:
        buttonStyles.push('');
        iconStyles.push('w-6 h-6');
        break;
    }

    return {
      buttonStyle: clsx(buttonStyles),
      iconStyle: clsx(iconStyles),
    };
  }, [size]);

  const children = useMemo(
    () => (
      <>
        <span className="sr-only">{label}</span>
        {cloneElement(icon, {
          className: iconStyle,
          'aria-hidden': true,
        })}
        <span className="absolute -inset-2 rounded-full" />
      </>
    ),
    [label, icon, iconStyle],
  );

  return to?.length > 0 ? (
    <Link to={to} className={buttonStyle}>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={buttonStyle}>
      {children}
    </button>
  );
}

IconButton.propTypes = {
  to: yup.string().required().pt(),
  label: yup.string().required().pt(),
  icon: yup.mixed().react().optional().pt(),
  size: yup.string().oneOf(['normal', 'large']).pt(),
  onClick: yup.mixed().callback().pt(),
};
