import { useEffect } from 'react';
import { usePrevious, useLatest } from 'react-use';

export function useChange(value, onChange) {
  const latestOnChange = useLatest(onChange);
  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value !== previousValue) {
      latestOnChange.current(value, previousValue);
    }
  }, [value, previousValue, latestOnChange]);
}
