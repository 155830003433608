/*eslint import/no-unresolved: [2, { ignore: ['^jsx:.+\\.svg$'] }]*/
import LoadingIcon from 'jsx:../../assets/loading.svg';

export function LoadingSpinner() {
  return (
    <div className="flex flex-row flex-nowrap items-center space-x-2" data-testid="loading-spinner">
      <LoadingIcon className="size-4" />
      <div className="text-sm">Loading...</div>
    </div>
  );
}
