import pino from 'pino';

const logger = pino({
  level: 'debug',
  browser: {
    asObject: true,
    write: {
      trace: function (o) {
        log(console.debug, o);
      },
      debug: function (o) {
        log(console.debug, o);
      },
      info: function (o) {
        log(console.info, o);
      },
      warn: function (o) {
        log(console.warn, o);
      },
      error: function (o) {
        log(console.error, o);
      },
    },
  },
});

function log(func, { time, level, msg, ...rest }) {
  try {
    level;
    const t = new Date(time);
    const args = [
      `[${t.getHours()}:${t.getMinutes()}:${t.getSeconds()}.${t.getMilliseconds()}]`,
      msg,
    ];
    if (Object.keys(rest).length > 0) {
      args.push(rest);
    }
    func.apply(console, args);
  } catch {
    // Ignore log failures
  }
}

export { logger };
