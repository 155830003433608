import { GUID_REGEX, NHI_REGEX } from '../../constants';
import { yup } from '../../utils';

export const APPOINTMENT_FORM_SCHEMA = yup.object().shape({
  patientId: yup
    .mixed()
    .nullable()
    .test({
      name: 'valid-patient-id',
      test: (value) => {
        return value === undefined || value === null || GUID_REGEX.test(value);
      },
    }),
  nhiNumber: yup.string().when('patientId', {
    is: (value) => value !== undefined,
    then: (schema) => schema.matches(NHI_REGEX, 'A valid NHI is required'),
  }),
  nameGiven: yup.string(),
  nameFamily: yup.string(),
  email: yup.string().email('Email not valid'),
  phoneNumber: yup.string(),
  referralSource: yup.string().required('Referral source is required'),
  procedureCodeId: yup.string().required('Procedure selection is required'),
  appointmentStatus: yup.string().required('Status selection is required'),
  appointmentNote: yup.string().max(100, 'Appointment note must be at most 100 characters long'),
});
