import clsx from 'clsx';
import { yup } from '../utils';

export function Label({ label, className }) {
  return label?.length > 0 ? (
    <div
      className={clsx(
        'inline-block text-center px-3 py-1 text-xs font-semibold rounded-full',
        className,
      )}
    >
      {label}
    </div>
  ) : null;
}

Label.propTypes = {
  label: yup.string().pt(),
  className: yup.string().pt(),
};
