import { useCallback, useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { Button } from '../button';
import { FilledSlotRow } from './filled-slot-row';
import { COLUMNS } from './shared';
import { EmptySlotRow } from './empty-slot-row';
import { ExtraSlotRow } from './extra-slot-row';
import { LIST_SCHEMA } from '../../api/schemas';
import { TIMEZONE } from '../../constants';
import { dayjs } from '../../utils';
import { useIsHistoric } from '../../hooks/use-is-historic';

export function AppointmentsTable({ list }) {
  const [extraSlots, setExtraSlots] = useState([]);
  const isHistory = useIsHistoric(list.dateTimestamp);

  const addExtraSlot = useCallback(() => {
    setExtraSlots((current) => [
      ...current,
      { id: Date.now(), initialValue: lastArrivalTimeOnList(list).add(30, 'm').format('HH:mm') },
    ]);
  }, [list]);

  const removeExtraSlot = useCallback(
    (id) => setExtraSlots((current) => current.filter((slot) => slot.id !== id)),
    [],
  );

  return (
    <div className="relative">
      <div className="rounded-lg border border-gray-200 relative mt-2 mb-4">
        <table className="table-fixed border-collapse w-full text-sm">
          <thead>
            <tr className="bg-gray-50 text-gray-700 uppercase tracking-wide text-left font-normal text-xs h-14">
              <th className={COLUMNS.slot.class}>#</th>
              <th className={COLUMNS.arrival.class}>Arrival</th>
              <th className={COLUMNS.status.class}>Status </th>
              <th className={COLUMNS.procedure.class}>Procedure</th>
              <th className={COLUMNS.note.class}>
                <span className="sr-only">Procedure note</span>
              </th>
              <th className={COLUMNS.nhi.class}>NHI</th>
              <th className={COLUMNS.family.class}>Family name</th>
              <th className={COLUMNS.given.class}>Given Name</th>
              <th className={COLUMNS.phone.class}>Phone</th>
              <th className={COLUMNS.email.class}>Email</th>
              <th className={COLUMNS.source.class}>Source</th>
              <th className={COLUMNS.webpas.class}>WebPAS</th>
              <th className={COLUMNS.primary.class}>
                <span className="sr-only">Primary action</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.calendarSlots.map((slot, index) =>
              slot.appointment ? (
                <FilledSlotRow key={slot.id} slot={slot} index={index + 1} />
              ) : (
                <EmptySlotRow key={slot.id} slot={slot} index={index + 1} />
              ),
            )}
            {extraSlots.map((slot, index) => (
              <ExtraSlotRow
                key={slot.id}
                index={list.calendarSlots.length + 1 + index}
                listId={list.id}
                initialValue={slot.initialValue}
                removeSlot={() => removeExtraSlot(slot.id)}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Button
        label="Add extra slot"
        icon={PlusCircleIcon}
        variant="outline"
        color="gray"
        onClick={addExtraSlot}
        disabled={isHistory}
      />
    </div>
  );
}

AppointmentsTable.propTypes = {
  list: LIST_SCHEMA.required().pt(),
};

function lastArrivalTimeOnList(list) {
  return dayjs(
    list.calendarSlots.reduce((acc, val) => Math.max(acc, val.arrivalTimeTimestamp), 0),
  ).tz(TIMEZONE);
}
