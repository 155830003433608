import { useEnum } from '../api/store/enums';
import { useProcedureCodesState } from '../api/store/procedures';

export function usePreloadData() {
  useProcedureCodesState();
  useEnum('AppointmentCancellationReason');
  useEnum('AppointmentCancellationTime');
  useEnum('AppointmentStatus');
  useEnum('CalendarSlotStatus');
  useEnum('CalenderSlotStatusReason');
  useEnum('ListType');
  useEnum('ReferralSource');
}
