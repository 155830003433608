import clsx from 'clsx';
import { yup } from '../utils';

export function AvailabilityDots({ availability }) {
  if (availability) {
    return (
      <div className="flex flex-row flex-nowrap space-x-[1px]">
        {availability.map((status, index) => (
          <AvailabilityDot key={index} status={status} />
        ))}
      </div>
    );
  } else {
    return null;
  }
}

AvailabilityDots.propTypes = {
  availability: yup.array().of(yup.string()).nullable(true).pt(),
};

function AvailabilityDot({ status }) {
  let style = 'bg-gray-300';
  switch (status) {
    case 'green':
      style = 'bg-green-400';
      break;
    case 'orange':
      style = 'bg-orange-400';
      break;
  }
  return <div className={clsx('rounded-full size-1', style)}></div>;
}

AvailabilityDot.propTypes = {
  status: yup.string().pt(),
};
