import { useFormState } from 'react-hook-form';
import { TextField } from '../form/text-field';
import { Td } from './shared';

export function PatientDetailsFormFields() {
  const { isSubmitting, isSubmitted, isLoading } = useFormState();
  const disabled = isSubmitting || isSubmitted || isLoading;
  return (
    <>
      <Td className="uppercase bg-gray-50">
        <TextField field="nhiNumber" placeholder="NHI" errorTooltip disabled />
      </Td>
      <Td className="uppercase bg-gray-50">
        <TextField field="nameFamily" placeholder="Family name" errorTooltip disabled={disabled} />
      </Td>
      <Td className="bg-gray-50">
        <TextField field="nameGiven" placeholder="Given name" errorTooltip disabled={disabled} />
      </Td>
      <Td className="bg-gray-50">
        <TextField field="phoneNumber" placeholder="Phone" errorTooltip disabled={disabled} />
      </Td>
      <Td className="bg-gray-50">
        <TextField field="email" placeholder="Email" errorTooltip disabled={disabled} />
      </Td>
    </>
  );
}
