/*eslint import/no-unresolved: [2, { ignore: ['^jsx:.+\\.svg$'] }]*/
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import SoopaLogo from 'jsx:../../assets/soopa-logo.svg';
import { Link } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { soopaSignOut } from '../../app/pages/login/soopa';
import { microsoftSignOut } from '../../app/pages/login/microsoft';
import { AUTH_PROVIDER_MICROSOFT, AUTH_PROVIDER_SOOPA } from '../constants';
import {
  useAuthenticatedIdentity,
  useAuthenticationDetails,
  usePreviousAuthProvider,
} from '../api/store/authentication';
import { DashboardNavigation } from './dashboard-navigation';
import clsx from 'clsx';
import { useEnvironmentClassName } from '../api/store/health';

const userNavigation = [
  // { name: 'Your Profile', href: '/profile' },
  // { name: 'Settings', href: '/settings' },
];

export function MainNavigation() {
  const user = useAuthenticatedIdentity();
  const environmentClass = useEnvironmentClassName();
  const userNavigationMenuItems = useMemo(
    () => [
      ...userNavigation.map((item) => (
        <Menu.Item key={item.id}>
          <Link
            to={item.href}
            onClick={item.onClick}
            className="block px-4 py-2 text-sm text-gray-700 ui-active:bg-gray-100"
            data-testid={item.id}
          >
            {item.name}
          </Link>
        </Menu.Item>
      )),
      <SignOutButtonMenuItem key="sign-out-menu-item" />,
    ],
    [],
  );
  return (
    <nav className="w-full flex min-h-16 h-16 items-center mb-4">
      <div
        className={clsx(
          environmentClass,
          'grow-0 shrink-0 flex items-center bg-brand px-6 h-full rounded-br-lg mr-4',
        )}
      >
        <Link to="/">
          <SoopaLogo />
        </Link>
      </div>
      <h1 className="mr-4 px-4 uppercase text-brand text-xl font-bold hidden 2xl:block">
        Calendar
      </h1>
      <div>
        <DashboardNavigation />
      </div>
      <div className="grow"></div>
      <div className="hidden md:block">
        <div className="mr-4 flex items-center md:ml-6">
          <Menu as="div" className="relative ml-3">
            <div className="relative flex max-w-xs items-center">
              <span
                className="px-3 text-brand text-sm hidden 2xl:inline text-right"
                aria-hidden="true"
              >
                {user?.name}
              </span>
              <Menu.Button
                className=" rounded-full bg-brand text-white text-sm focus:outline-none hover:ring-1 hover:ring-white/25"
                data-testid="open-menu"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Open user menu</span>
                <EllipsisVerticalIcon className="size-7 p-1" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <span
                  className="block px-4 py-2 text-sm text-gray-400 2xl:hidden border-b"
                  aria-hidden="true"
                >
                  {user?.name}
                </span>
                {userNavigationMenuItems}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </nav>
  );
}

function SignOutButtonMenuItem() {
  const [working, setWorking] = useState(false);
  const { provider } = useAuthenticationDetails();
  const [, resetStoredProvider] = usePreviousAuthProvider();
  const signOut = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setWorking(true);
        resetStoredProvider();
        switch (provider) {
          case AUTH_PROVIDER_SOOPA: {
            await soopaSignOut();
            break;
          }
          case AUTH_PROVIDER_MICROSOFT: {
            await microsoftSignOut();
            break;
          }
          default:
            invariant(false, 'Invalid provider');
        }
      } catch (error) {
        console.warn('Sign out failed', error);
      } finally {
        setWorking(false);
      }
      window.location.href = '/';
    },
    [provider, resetStoredProvider],
  );

  return (
    <Menu.Item>
      <button
        className="block w-full text-left px-4 py-2 text-sm text-gray-700 ui-active:bg-gray-100"
        onClick={signOut}
        disabled={working}
        data-testid="menu-logout"
      >
        Sign out
      </button>
    </Menu.Item>
  );
}
