import { ClockIcon } from '@heroicons/react/20/solid';
import { useController } from 'react-hook-form';
import SimpleTimeField from 'react-simple-timefield';
import { yup } from '../../utils';

export function TimeField({ field, disabled }) {
  const controller = useController({ name: field });
  return (
    <div className="relative flex items-center">
      <SimpleTimeField
        value={controller.field.value}
        onChange={(event, value) => controller.field.onChange(value)}
        showSeconds={false}
        input={
          <input
            type="text"
            id={field}
            value={controller.field.value}
            onBlur={controller.field.onBlur}
            className="block w-24 rounded-md border-0 py-1.5 pr-8 shadow-sm ring-1 focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
            disabled={disabled}
          />
        }
      />
      <ClockIcon className="w-5 absolute right-2 pointer-events-none" />
    </div>
  );
}

TimeField.propTypes = {
  field: yup.string().required().pt(),
  disabled: yup.boolean().pt(),
};
