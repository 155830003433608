import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  INITIAL_THUNK_STATE,
  useStateWithSuspendFlag,
  createAsyncApiThunk,
  useSharedDispatch,
  setThunkStatePending,
  setThunkStateFulfilled,
  setThunkStateRejected,
  transformArrayToMapping,
} from './utils';
import { logger } from '../../logger';
import { EMPTY_OBJECT } from '../../constants';
import { transformSearchResult } from './transformations';

export const fetchSearch = createAsyncApiThunk('search/fetch', async (searchTerm, { apiPost }) => {
  const rawAppointments = await apiPost('appointment/search', { searchTerm });
  logger.info(`Received ${rawAppointments.length} search results`);
  return transformArrayToMapping(rawAppointments);
});

const searchSlice = createSlice({
  name: 'search',
  initialState: { ...INITIAL_THUNK_STATE },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSearch.pending, (state, action) => {
        setThunkStatePending(state);
        if (state.term !== action.meta.arg) {
          state.loaded = false;
          state.data = EMPTY_OBJECT;
        }
        state.term = action.meta.arg;
      })
      .addCase(fetchSearch.fulfilled, (state, action) => {
        setThunkStateFulfilled(state, action.payload);
      })
      .addCase(fetchSearch.rejected, (state, action) => {
        setThunkStateRejected(state, action.error);
      });
  },
});

export default searchSlice.reducer;

const searchResultsSelector = createSelector([searchSlice.selectSlice], (slice) => {
  const searchResults = Object.values(slice.data).reduce((acc, rawSearchResult) => {
    const searchResult = transformSearchResult(rawSearchResult);
    acc[searchResult.id] = searchResult;
    return acc;
  }, {});
  return { ...slice, data: searchResults };
});

export function useIsSearchingAppointments() {
  const state = useSelector(searchSlice.selectSlice);
  return state.loading;
}

export function useSearchAppointments(searchTerm) {
  useSharedDispatch(fetchSearch, searchTerm, { pollInterval: 300 * 1000 });
  const state = useSelector(searchResultsSelector);
  return useStateWithSuspendFlag(state);
}
